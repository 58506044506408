import "../css/blockimage.css";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";

function BlockLeftImage(props) {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);

    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  return (
    <div className="image-text-container left-image">
      {/* Image Column */}

      <div className="image-column">
        <img src={props.src} alt={props.alt} />
      </div>

      {/* Text Column */}
      {isMobile ? (
        <div className="text-column">{props.content}</div>
      ) : (
        <motion.div
          initial={{ opacity: 0, x: 70 }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true, amount: 0.2 }}
          className="text-column"
        >
          {props.content}
        </motion.div>
      )}
    </div>
  );
}

export default BlockLeftImage;
