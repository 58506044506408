import BannerGeneral from "../components/BannerGeneral";
import BlockBackgroundImage from "../components/BlockBackgroundImage";
import BlockImageCabecera from "../components/BlockImageCabecera";
import useContentful from "../hooks/use-contenful";
import Notification from "../components/Notification";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Breadcumbs from "../components/Breadcumbs";
import { NavLink } from "react-router-dom";
import ConectUs from "../components/ConectUs";
import ModalFixed from "../components/ModalFixed";
import PresencialOffer from "../components/PresencialOffer";
import PresencialPagos from "../components/PresencialPagos";
import Modal from "../components/Modal";
import { PresencialInscripcionFixed } from "../components/Pasos";
import BlockLeftImage from "../components/BlockLeftImage";

const query = `
query 
{
    videoTemplate(id: "7cnwxQRK0Yg9weoT5lPLNq") {
      imageBlock1{url}
      imageBlock5{url, title}
      contentBlock1{json}
      contentBlock2{json}
      contentBlock3{json}
      contentBlock4{json}
      contentBlock5{json}
        imageBlock6{url}
       contentBlock6{json}
      imageBlock3{url}
      galleryCollection{
        items{url title}
         }

         gallery2Collection{
          items{url title}
           }
           gallery3Collection{
            items{url title}
             }
    }
}
`;
function Presencial() {
  let { data, errors } = useContentful(query);
  if (errors)
    return <span>{errors.map((error) => error.message).join(",")}</span>;
  if (!data) return <p>Loading...</p>;

  return (
    <>
      <div className="margin relative">
        <BlockImageCabecera
          src={data.videoTemplate.imageBlock1.url}
          title={
            <>
              {documentToReactComponents(data.videoTemplate.contentBlock1.json)}
            </>
          }
        ></BlockImageCabecera>

        <ModalFixed
          color="#00bcd4"
          title="Conoce los pasos para inscribirte a la prueba 💙🐆"
          content={
            <>
              <iframe
                width="100%"
                height="400"
                src="https://www.youtube.com/embed/u-v0tQFxWa0?si=_o-iZN8h_44JqCyy"
                frameborder="0"
                allowfullscreen
              ></iframe>
            </>
          }
        >
          {" "}
        </ModalFixed>
      </div>
      <Breadcumbs
        content={
          <>
            <NavLink to="/presencial">Modalidad presencial</NavLink>
          </>
        }
      ></Breadcumbs>
      <PresencialOffer
        title={
          <>
            {documentToReactComponents(data.videoTemplate.contentBlock4.json)}
          </>
        }
        subtitle="Elige tu carrera"
      ></PresencialOffer>
      <div className="relative graybg">
        <BlockLeftImage
          src={data.videoTemplate.imageBlock6.url}
          content={
            <>
              <div className="tematicas-prueba">
                {documentToReactComponents(
                  data.videoTemplate.contentBlock6.json
                )}
                <Modal
                  color="#6d46ce"
                  id="tematicas"
                  title="Consulta las temáticas de la prueba 📘"
                  content={
                    <>
                      <img
                        src={data.videoTemplate.imageBlock5.url}
                        alt={data.videoTemplate.imageBlock5.title}
                      ></img>
                    </>
                  }
                  Fi
                ></Modal>
              </div>
            </>
          }
        ></BlockLeftImage>
      </div>
      <div className="padding">
        <PresencialPagos></PresencialPagos>
      </div>
      <BlockBackgroundImage
        src={data.videoTemplate.imageBlock3.url}
        content={documentToReactComponents(
          data.videoTemplate.contentBlock3.json
        )}
      ></BlockBackgroundImage>{" "}
      <ConectUs
        lema="Descubre contenido exclusivo, mantente al tanto de las últimas novedades y únete a nuestra comunidad de estudiantes presenciales."
        facebook="https://www.facebook.com/utplpresencial/"
        instagram="https://www.instagram.com/utpl/"
        tiktok="https://www.tiktok.com/@utpl.ec"
      ></ConectUs>
      <BannerGeneral></BannerGeneral>
    </>
  );
}

export default Presencial;
