import { NavLink } from "react-router-dom";
import Breadcumbs from "../components/Breadcumbs";
import DataFetcherTemplate from "../hooks/fetchTemplate";
import BannerGeneral from "../components/BannerGeneral";
import Flipcard from "../components/Flipcard";
import Modal from "../components/Modal";

function Gobernanza() {
  const DataDisplay = ({ data }) => (
    <>
      {data.map((item, index) => (
        <div key={index}>
          <div>
            <div className="layout9">
              <div
                dangerouslySetInnerHTML={{
                  __html: item.field_texto_portada,
                }}
              />
              <div className="flex-center">
                <Flipcard
                  src={item.field_imagen1}
                  alt="utpl"
                  content={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.field_bloque1,
                      }}
                    />
                  }
                ></Flipcard>
                <Flipcard
                  src={item.field_imagen2}
                  alt="utpl"
                  content={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.field_bloque2,
                      }}
                    />
                  }
                ></Flipcard>
                <Flipcard
                  src={item.field_imagen3}
                  alt="utpl"
                  content={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.field_bloque3,
                      }}
                    />
                  }
                ></Flipcard>
                <Flipcard
                  src={item.field_imagen4}
                  alt="utpl"
                  content={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.field_bloque4,
                      }}
                    />
                  }
                ></Flipcard>
                <Flipcard
                  src={item.field_imagen5}
                  alt="utpl"
                  content={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.field_bloque5,
                      }}
                    />
                  }
                ></Flipcard>
                <Flipcard
                  src={item.field_imagen6}
                  alt="utpl"
                  content={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.field_bloque6,
                      }}
                    />
                  }
                ></Flipcard>
                <Flipcard
                  src={item.field_imagen7}
                  alt="utpl"
                  content={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.field_bloque7,
                      }}
                    />
                  }
                ></Flipcard>
              </div>
            </div>
          </div>
          <div className="layout8 padding2">
            <div
              dangerouslySetInnerHTML={{
                __html: item.field_bloque8,
              }}
            />
            <div className="flex-center">
              <Flipcard
                src={item.field_imagen1}
                alt="utpl"
                content={
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.field_bloque1,
                    }}
                  />
                }
              ></Flipcard>
              <Flipcard
                src={item.field_imagen9}
                alt="utpl"
                content={
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.field_bloque9,
                    }}
                  />
                }
              ></Flipcard>
              <Flipcard
                src={item.field_imagen10}
                alt="utpl"
                content={
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.field_bloque10,
                    }}
                  />
                }
              ></Flipcard>

              <Flipcard
                src={item.field_imagen2}
                alt="utpl"
                content={
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.field_bloque2,
                    }}
                  />
                }
              ></Flipcard>

              <Flipcard
                src={item.field_imagen3}
                alt="utpl"
                content={
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.field_bloque3,
                    }}
                  />
                }
              ></Flipcard>
              <Flipcard
                src={item.field_imagen11}
                alt="utpl"
                content={
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.field_bloque11,
                    }}
                  />
                }
              ></Flipcard>
              <Flipcard
                src={item.field_imagen7}
                alt="utpl"
                content={
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.field_bloque7,
                    }}
                  />
                }
              ></Flipcard>
            </div>{" "}
          </div>
        </div>
      ))}
    </>
  );

  const DataDisplay2 = ({ data }) => (
    <>
      {data.map((item, index) => (
        <div key={index}>
          <div>
            <div className="layout9">
              <div
                dangerouslySetInnerHTML={{
                  __html: item.field_texto_portada,
                }}
              />
              <div className="flex-center">
                <Flipcard
                  src={item.field_imagen1}
                  alt="utpl"
                  content={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.field_bloque1,
                      }}
                    />
                  }
                ></Flipcard>
                <Flipcard
                  src={item.field_imagen2}
                  alt="utpl"
                  content={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.field_bloque2,
                      }}
                    />
                  }
                ></Flipcard>
                <Flipcard
                  src={item.field_imagen3}
                  alt="utpl"
                  content={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.field_bloque3,
                      }}
                    />
                  }
                ></Flipcard>
                <Flipcard
                  src={item.field_imagen4}
                  alt="utpl"
                  content={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.field_bloque4,
                      }}
                    />
                  }
                ></Flipcard>
                <Flipcard
                  src={item.field_imagen5}
                  alt="utpl"
                  content={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.field_bloque5,
                      }}
                    />
                  }
                ></Flipcard>
                <Flipcard
                  src={item.field_imagen6}
                  alt="utpl"
                  content={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.field_bloque6,
                      }}
                    />
                  }
                ></Flipcard>
                <Flipcard
                  src={item.field_imagen7}
                  alt="utpl"
                  content={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.field_bloque7,
                      }}
                    />
                  }
                ></Flipcard>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );

  const DataDisplay3 = ({ data }) => (
    <>
      {data.map((item, index) => (
        <div key={index}>
          <div className="wrapper-gobernanza">
            <div>
              <div
                dangerouslySetInnerHTML={{
                  __html: item.field_texto_portada,
                }}
              />
              <div className="flex-center">
                <Flipcard
                  src={item.field_imagen1}
                  alt="utpl"
                  content={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.field_bloque1,
                      }}
                    />
                  }
                ></Flipcard>
                <Flipcard
                  src={item.field_imagen2}
                  alt="utpl"
                  content={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.field_bloque2,
                      }}
                    />
                  }
                ></Flipcard>
                <Flipcard
                  src={item.field_imagen3}
                  alt="utpl"
                  content={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.field_bloque3,
                      }}
                    />
                  }
                ></Flipcard>
                <Flipcard
                  src={item.field_imagen4}
                  alt="utpl"
                  content={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.field_bloque4,
                      }}
                    />
                  }
                ></Flipcard>
                <Flipcard
                  src={item.field_imagen5}
                  alt="utpl"
                  content={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.field_bloque5,
                      }}
                    />
                  }
                ></Flipcard>
              </div>
            </div>

            <div>
              <div
                dangerouslySetInnerHTML={{
                  __html: item.field_bloque6,
                }}
              />
              <div className="flex-center">
                <Flipcard
                  src={item.field_imagen7}
                  alt="utpl"
                  content={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.field_bloque7,
                      }}
                    />
                  }
                ></Flipcard>
                <Flipcard
                  src={item.field_imagen8}
                  alt="utpl"
                  content={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.field_bloque8,
                      }}
                    />
                  }
                ></Flipcard>
                <Flipcard
                  src={item.field_imagen9}
                  alt="utpl"
                  content={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.field_bloque9,
                      }}
                    />
                  }
                ></Flipcard>
                <Flipcard
                  src={item.field_imagen10}
                  alt="utpl"
                  content={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.field_bloque10,
                      }}
                    />
                  }
                ></Flipcard>
                <Flipcard
                  src={item.field_imagen11}
                  alt="utpl"
                  content={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.field_bloque11,
                      }}
                    />
                  }
                ></Flipcard>
                <Flipcard
                  src={item.field_imagen12}
                  alt="utpl"
                  content={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.field_bloque12,
                      }}
                    />
                  }
                ></Flipcard>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );

  const DataDisplay4 = ({ data }) => (
    <>
      {data.map((item, index) => (
        <div key={index}>
          <div className="wrapper-gobernanza">
            <div>
              <div
                dangerouslySetInnerHTML={{
                  __html: item.field_texto_portada,
                }}
              />
              <div className="flex-center">
                <Flipcard
                  src={item.field_imagen1}
                  alt="utpl"
                  content={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.field_bloque1,
                      }}
                    />
                  }
                ></Flipcard>
                <Flipcard
                  src={item.field_imagen2}
                  alt="utpl"
                  content={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.field_bloque2,
                      }}
                    />
                  }
                ></Flipcard>
                <Flipcard
                  src={item.field_imagen3}
                  alt="utpl"
                  content={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.field_bloque3,
                      }}
                    />
                  }
                ></Flipcard>
                <Flipcard
                  src={item.field_imagen4}
                  alt="utpl"
                  content={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.field_bloque4,
                      }}
                    />
                  }
                ></Flipcard>
                <Flipcard
                  src={item.field_imagen5}
                  alt="utpl"
                  content={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.field_bloque5,
                      }}
                    />
                  }
                ></Flipcard>
              </div>
            </div>

            <div>
              <div
                dangerouslySetInnerHTML={{
                  __html: item.field_bloque6,
                }}
              />
              <div className="flex-center">
                <Flipcard
                  src={item.field_imagen7}
                  alt="utpl"
                  content={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.field_bloque7,
                      }}
                    />
                  }
                ></Flipcard>
                <Flipcard
                  src={item.field_imagen8}
                  alt="utpl"
                  content={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.field_bloque8,
                      }}
                    />
                  }
                ></Flipcard>
                <Flipcard
                  src={item.field_imagen9}
                  alt="utpl"
                  content={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.field_bloque9,
                      }}
                    />
                  }
                ></Flipcard>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );

  const DataDisplay5 = ({ data }) => (
    <>
      {data.map((item, index) => (
        <div key={index}>
          <div className="wrapper-gobernanza">
            <div>
              <div
                dangerouslySetInnerHTML={{
                  __html: item.field_texto_portada,
                }}
              />
              <div className="flex-center">
                <Flipcard
                  src={item.field_imagen1}
                  alt="utpl"
                  content={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.field_bloque1,
                      }}
                    />
                  }
                ></Flipcard>
                <Flipcard
                  src={item.field_imagen2}
                  alt="utpl"
                  content={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.field_bloque2,
                      }}
                    />
                  }
                ></Flipcard>
                <Flipcard
                  src={item.field_imagen3}
                  alt="utpl"
                  content={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.field_bloque3,
                      }}
                    />
                  }
                ></Flipcard>
                <Flipcard
                  src={item.field_imagen4}
                  alt="utpl"
                  content={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.field_bloque4,
                      }}
                    />
                  }
                ></Flipcard>
              </div>
              <div className="flex-center">
                <Flipcard
                  src={item.field_imagen5}
                  alt="utpl"
                  content={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.field_bloque5,
                      }}
                    />
                  }
                ></Flipcard>
                <Flipcard
                  src={item.field_imagen6}
                  alt="utpl"
                  content={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.field_bloque6,
                      }}
                    />
                  }
                ></Flipcard>

                <Flipcard
                  src={item.field_imagen7}
                  alt="utpl"
                  content={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.field_bloque7,
                      }}
                    />
                  }
                ></Flipcard>
              </div>
            </div>

            <div>
              <div
                dangerouslySetInnerHTML={{
                  __html: item.field_bloque8,
                }}
              />

              <div className="flex-center">
                <Flipcard
                  src={item.field_imagen9}
                  alt="utpl"
                  content={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.field_bloque9,
                      }}
                    />
                  }
                ></Flipcard>
              </div>
            </div>

            <div>
              <div
                dangerouslySetInnerHTML={{
                  __html: item.field_bloque10,
                }}
              />
              <div className="flex-center">
                <Flipcard
                  src={item.field_imagen11}
                  alt="utpl"
                  content={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.field_bloque11,
                      }}
                    />
                  }
                ></Flipcard>

                <Flipcard
                  src={item.field_imagen12}
                  alt="utpl"
                  content={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.field_bloque12,
                      }}
                    />
                  }
                ></Flipcard>
                <div>
                  <Flipcard
                    src={item.field_imagen13}
                    alt="utpl"
                    content={
                      <>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.field_bloque13,
                          }}
                        />
                      </>
                    }
                  ></Flipcard>

                  <Modal
                    title="Autoridades estudiantiles 👆🏼"
                    content={
                      <>
                        <div className="graybg">
                          <div className="layout8 padding">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: item.field_bloque14,
                              }}
                            />
                          </div>
                        </div>
                      </>
                    }
                  ></Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );

  const DataDisplay6 = ({ data }) => (
    <>
      {data.map((item, index) => (
        <div key={index}>
          <div className="wrapper-gobernanza">
            <div>
              <div
                dangerouslySetInnerHTML={{
                  __html: item.field_texto_portada,
                }}
              />
              <div className="flex-center">
                <div>
                  <Flipcard
                    src={item.field_imagen1}
                    alt="utpl"
                    content={
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.field_bloque1,
                        }}
                      />
                    }
                  ></Flipcard>
                  <Modal
                    title="Autoridades facultad👆🏼"
                    content={
                      <>
                        <div className="graybg">
                          <div className="layout8 padding">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: item.field_bloque2,
                              }}
                            />
                          </div>
                        </div>
                      </>
                    }
                  ></Modal>
                </div>
                <div>
                  <Flipcard
                    src={item.field_imagen3}
                    alt="utpl"
                    content={
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.field_bloque3,
                        }}
                      />
                    }
                  ></Flipcard>
                  <Modal
                    title="Autoridades facultad 👆🏼"
                    content={
                      <>
                        <div className="graybg">
                          <div className="layout8 padding">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: item.field_bloque4,
                              }}
                            />
                          </div>
                        </div>
                      </>
                    }
                  ></Modal>
                </div>
                <div>
                  <Flipcard
                    src={item.field_imagen5}
                    alt="utpl"
                    content={
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.field_bloque5,
                        }}
                      />
                    }
                  ></Flipcard>
                  <Modal
                    title="Autoridades facultad 👆🏼"
                    content={
                      <>
                        <div className="graybg">
                          <div className="layout8 padding">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: item.field_bloque6,
                              }}
                            />
                          </div>
                        </div>
                      </>
                    }
                  ></Modal>
                </div>
                <div>
                  <Flipcard
                    src={item.field_imagen7}
                    alt="utpl"
                    content={
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.field_bloque7,
                        }}
                      />
                    }
                  ></Flipcard>
                  <Modal
                    title="Autoridades facultad 👆🏼"
                    content={
                      <>
                        <div className="graybg">
                          <div className="layout8 padding">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: item.field_bloque8,
                              }}
                            />
                          </div>
                        </div>
                      </>
                    }
                  ></Modal>
                </div>
                <div>
                  <Flipcard
                    src={item.field_imagen9}
                    alt="utpl"
                    content={
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.field_bloque9,
                        }}
                      />
                    }
                  ></Flipcard>
                  <Modal
                    title="Autoridades facultad 👆🏼"
                    content={
                      <>
                        <div className="graybg">
                          <div className="layout8 padding">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: item.field_bloque10,
                              }}
                            />
                          </div>
                        </div>
                      </>
                    }
                  ></Modal>
                </div>

                <div>
                  <Flipcard
                    src={item.field_imagen11}
                    alt="utpl"
                    content={
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.field_bloque11
                        }}
                      />
                    }
                  ></Flipcard>
                  <Modal
                    title="Autoridades facultad 👆🏼"
                    content={
                      <>
                        <div className="graybg">
                          <div className="layout8 padding">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: item.field_bloque12
                              }}
                            />
                          </div>
                        </div>
                      </>
                    }
                  ></Modal>
                </div>
                <div>
                  <Flipcard
                    src={item.field_imagen13}
                    alt="utpl"
                    content={
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.field_bloque13
                        }}
                      />
                    }
                  ></Flipcard>
                  <Modal
                    title="Autoridades UTPL TEC 👆🏼"
                    content={
                      <>
                        <div className="graybg">
                          <div className="layout8 padding">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: item.field_bloque14
                              }}
                            />
                          </div>
                        </div>
                      </>
                    }
                  ></Modal>
                </div>

                <div>
                  <Flipcard
                    src={item.field_imagen15}
                    alt="utpl"
                    content={
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.field_bloque15
                        }}
                      />
                    }
                  ></Flipcard>
                  <Modal
                    title="Autoridades EDES 👆🏼"
                    content={
                      <>
                        <div className="graybg">
                          <div className="layout8 padding">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: item.field_bloque16
                              }}
                            />
                          </div>
                        </div>
                      </>
                    }
                  ></Modal>
                </div>


              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
  return (
    <>
      <div className="margin">
        <Breadcumbs
          content={
            <>
              <NavLink to="/gobernanza">Gobernanza</NavLink>
            </>
          }
        ></Breadcumbs>
      </div>

      <center>
        <h2>
          <b>Gobernanza</b>
        </h2>
        <p>Descubre la estructura de gobernanza de nuestra institución.</p>
      </center>

      <DataFetcherTemplate url="https://utpl.edu.ec/carreras/services/servicio-gobernanza1.json">
        {(data) => <DataDisplay data={data} />}
      </DataFetcherTemplate>

      <DataFetcherTemplate url="https://utpl.edu.ec/carreras/services/servicio-gobernanza2.json">
        {(data) => <DataDisplay2 data={data} />}
      </DataFetcherTemplate>

      <DataFetcherTemplate url="https://utpl.edu.ec/carreras/services/servicio-gobernanza3.json">
        {(data) => <DataDisplay3 data={data} />}
      </DataFetcherTemplate>

      <DataFetcherTemplate url="https://utpl.edu.ec/carreras/services/servicio-gobernanza4.json">
        {(data) => <DataDisplay4 data={data} />}
      </DataFetcherTemplate>

      <DataFetcherTemplate url="https://utpl.edu.ec/carreras/services/servicio-gobernanza5.json">
        {(data) => <DataDisplay5 data={data} />}
      </DataFetcherTemplate>

      <DataFetcherTemplate url="https://utpl.edu.ec/carreras/services/servicio-gobernanza6.json">
        {(data) => <DataDisplay6 data={data} />}
      </DataFetcherTemplate>

      <BannerGeneral></BannerGeneral>
    </>
  );
}

export default Gobernanza;
