import { NavLink } from "react-router-dom";
import DataFetcherTemplate from "../../hooks/fetchTemplate";
import Breadcumbs from "../../components/Breadcumbs";
import BlockRightImage from "../../components/BlockRightImage";
import Accordion from "../../components/Accordion";
import Notification from "../../components/Notification";
import Block4Columns from "../../components/Block4ColumnsText";
import ModalFixed from "../../components/ModalFixed";
import Modal from "../../components/Modal";
import BlockLeftImage from "../../components/BlockLeftImage";
import BlockImageCabecera from "../../components/BlockImageCabecera";
import BannerGeneral from "../../components/BannerGeneral";
import ImageGallery from "../../components/ImageGallery";

const Competencias = () => {
  const DataDisplay = ({ data }) => (
    <>
      {data.map((item, index) => (
        <div key={index}>
          <BlockImageCabecera
            src={item.field_portada}
            title={
              <>
                <div
                  dangerouslySetInnerHTML={{ __html: item.field_texto_portada }}
                />
              </>
            }
          ></BlockImageCabecera>

          <div className="relative ">
            <Notification
              content={
                <>
                  <div
                    dangerouslySetInnerHTML={{ __html: item.field_bloque1 }}
                  />
                </>
              }
            ></Notification>

            <Breadcumbs
              content={
                <>
                  <NavLink to="/competencias">Competencias Específicas</NavLink>
                </>
              }
            ></Breadcumbs>
          </div>

          <div className="padding">
            <Block4Columns
              col1={
                <>
                  <div className="icon70">
                    {" "}
                    <img src={item.field_imagen2} alt="utpl"></img>
                  </div>

                  <div
                    dangerouslySetInnerHTML={{ __html: item.field_bloque2 }}
                  />
                </>
              }
              col2={
                <>
                  <div className="icon70">
                    {" "}
                    <img src={item.field_imagen2} alt="utpl"></img>
                  </div>

                  <div
                    dangerouslySetInnerHTML={{ __html: item.field_bloque3 }}
                  />
                </>
              }
              col3={
                <>
                  <div className="icon70">
                    {" "}
                    <img src={item.field_imagen4} alt="utpl"></img>
                  </div>

                  <div
                    dangerouslySetInnerHTML={{ __html: item.field_bloque4 }}
                  />
                </>
              }
              col4={
                <>
                  <div className="icon70">
                    {" "}
                    <img src={item.field_imagen5} alt="utpl"></img>
                  </div>

                  <div
                    className="nobutton"
                    dangerouslySetInnerHTML={{ __html: item.field_bloque5 }}
                  />
                </>
              }
            ></Block4Columns>
          </div>

          <div className="relative graybg ">
            <div>
              <BlockRightImage
                src={item.field_imagen6}
                content={
                  <>
                    <div
                      dangerouslySetInnerHTML={{ __html: item.field_bloque6 }}
                    />
                  </>
                }
              ></BlockRightImage>
            </div>

            <ModalFixed
              title="Proceso de legalización 📝"
              color="#5854a7"
              content={
                <div className="width400">
                  <ImageGallery gallery={item.field_gallery1}></ImageGallery>
                </div>
              }
            ></ModalFixed>
          </div>

          <div>
            <div className="relative">
              <BlockLeftImage
                src={item.field_imagen7}
                content={
                  <>
                    <div
                      dangerouslySetInnerHTML={{ __html: item.field_bloque7 }}
                    />
                  </>
                }
              ></BlockLeftImage>{" "}
              <ModalFixed
                title="Proceso de legalización 📝"
                color="#5854a7"
                content={
                  <div className="width400">
                    <ImageGallery gallery={item.field_gallery1}></ImageGallery>
                  </div>
                }
              ></ModalFixed>
            </div>
            <div className="layout8 width700 padding2">
              <Accordion
                title="¿Aprobaste el curso antes del periodo octubre 2023- febrero 2024?"
                content={
                  <>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.field_bloque8,
                      }}
                    />
                    <Modal
                      title="Proceso de homologación ✍🏼️"
                      color="#d67d3a"
                      content={
                        <div className="width400">
                          {" "}
                          <ImageGallery
                            gallery={item.field_gallery2}
                          ></ImageGallery>
                        </div>
                      }
                    ></Modal>
                  </>
                }
              ></Accordion>
            </div>
          </div>
        </div>
      ))}
    </>
  );

  return (
    <>
      <DataFetcherTemplate url="https://utpl.edu.ec/carreras/services/servicio-competencias.json">
        {(data) => <DataDisplay data={data} />}
      </DataFetcherTemplate>
      <BannerGeneral></BannerGeneral>
    </>
  );
};

export default Competencias;
