import Breadcumbs from "../components/Breadcumbs";
import { NavLink } from "react-router-dom";
import useContentful from "../hooks/use-contenful";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

import BlockLeftImage from "../components/BlockLeftImage";
import DataTable from "react-data-table-component";
import Accordion from "../components/Accordion";
import DataFetcherNews from "../hooks/fetchNews";
import { motion } from "framer-motion";

import caces from "../img/logoscalidad/caces-svg.png";
import acreditae from "../img/logoscalidad/acreditae.jpg";
import chile from "../img/logoscalidad/chile.jpg";
import equa from "../img/logoscalidad/equa.png";
import kalos from "../img/logoscalidad/kalos.jpeg";

const query = `
query 
{
    videoTemplate(id: "2hS4jkDstDUcDmoVmokdsT") {
    imageBlock1{url}
      contentBlock1{json}
      contentBlock2{json}
      contentBlock3{json}
         contentBlock4{json}
               contentBlock5{json}
      
    }
  
}
`;

const columns1 = [
  {
    name: "Agencia",
    selector: (row) => row.agencia,
    sortable: true,
    wrap: true,
  },
  {
    name: "Fecha de acreditación",
    selector: (row) => row.year,
    sortable: true,
    maxWidth: "200px",
  },
  {
    name: "Denominación",
    selector: (row) => row.denominacion,
    sortable: true,
    maxWidth: "150px",
  },
  {
    name: "Duración",
    selector: (row) => row.duracion,
    sortable: true,
    maxWidth: "120px",
  },
  {
    name: "Estado",
    selector: (row) => row.estado,
    sortable: true,
    maxWidth: "120px",
  },
];

const columns2 = [
  {
    name: "Facultad / Unidad Académica",
    selector: (row) => row.facultad,
    sortable: true,
    wrap: true,
    minWidth: "150px",
  },
  {
    name: "Carrera",
    selector: (row) => row.carrera,
    sortable: true,
    minWidth: "120px",
    wrap: true,
  },
  {
    name: "Modalidad",
    selector: (row) => row.modalidad,
    sortable: true,
    maxWidth: "100px",
    wrap: true,
  },
  {
    name: "Agencia",
    selector: (row) => row.agencia,
    sortable: true,
    minWidth: "200px",
    wrap: true,
  },
  {
    name: "País",
    selector: (row) => row.pais,
    sortable: true,
    maxWidth: "100px",
    wrap: true,
  },

  {
    name: "Fecha",
    selector: (row) => row.fecha,
    sortable: true,
    minWidth: "100px",
    wrap: true,
  },
  {
    name: "Denominación",
    selector: (row) => row.denominacion,
    sortable: true,
    maxWidth: "120px",
    wrap: true,
  },
  {
    name: "Alcance",
    selector: (row) => row.alcance,
    sortable: true,
    minWidth: "120px",
    wrap: true,
  },
  {
    name: "Duración",
    selector: (row) => row.duracion,
    sortable: true,
    maxWidth: "90px",
    wrap: true,
  },
  {
    name: "Estado",
    selector: (row) => row.estado,
    sortable: true,
    maxWidth: "90px",
    wrap: true,
  },
];
const columns3 = [
  {
    name: "Facultad / Unidad Académica",
    selector: (row) => row.facultad,
    sortable: true,
    wrap: true,
  },
  {
    name: "Posgrado",
    selector: (row) => row.posgrado,
    sortable: true,
    wrap: true,
  },
  {
    name: "Modalidad",
    selector: (row) => row.modalidad,
    sortable: true,
    maxWidth: "150px",
  },
  {
    name: "Agencia",
    selector: (row) => row.agencia,
    sortable: true,

    wrap: true,
  },
  {
    name: "País",
    selector: (row) => row.pais,
    sortable: true,
    maxWidth: "120px",
  },
  {
    name: "Alcance",
    selector: (row) => row.alcance,
    sortable: true,
    maxWidth: "120px",
  },
  {
    name: "Estado",
    selector: (row) => row.estado,
    sortable: true,
    maxWidth: "120px",
  },
];

const datatable1 = [
  {
    id: 2,
    agencia:
      "Consejo de Aseguramiento de la Calidad de la Educación Superior - CACES",
    year: "25 de octubre de 2020",
    denominacion: "Acreditada",
    duracion: "5 años",
    estado: "Vigente",
  },
];

const datatable2 = [

  {
    id: 5,
    facultad: "Facultad Ciencias de la Salud ",
    carrera: "Medicina",
    modalidad: "Presencial",
    agencia: "Acreditae",
    pais: (
      <>
        España{" "}
        <img src="https://eventos.utpl.edu.ec/sites/default/files/images/espania.png"></img>
      </>
    ),

    fecha: "Septiembre 2024",
    denominacion: "Certificación",
    alcance: "Internacional",
    duracion: "4 años",
    estado: "Vigente",
  },
  {
    id: 5,
    facultad: "Facultad de Ciencias Jurídicas y Políticas",
    carrera: "Derecho",
    modalidad: "A distancia",
    agencia: "Sello de Calidad Kalos Virtual Iberoamérica",
    pais: (
      <>
        México{" "}
        <img src="https://eventos.utpl.edu.ec/sites/default/files/images/mexico.png"></img>
      </>
    ),

    fecha: "Septiembre 2022",
    denominacion: "Acreditada",
    alcance: "Internacional",
    duracion: "6 años",
    estado: "Vigente",
  },
  {
    id: 6,
    facultad: "Facultad de Ciencias Jurídicas y Políticas",
    carrera: "Derecho",
    modalidad: "A distancia",
    agencia: "Consejo de Aseguramiento de la Calidad de la Educación Superior",
    pais: (
      <>
        Ecuador
        <img src="https://eventos.utpl.edu.ec/sites/default/files/images/ecuador.png"></img>
      </>
    ),
    fecha: "Agosto 2022",
    denominacion: "Acreditada",
    alcance: "Nacional",
    duracion: "5 años",
    estado: "Vigente",
  },
  {
    id: 7,
    facultad: "Facultad de Ciencias Jurídicas y Políticas",
    carrera: "Derecho",
    modalidad: "Presencial",
    agencia: "Consejo de Aseguramiento de la Calidad de la Educación Superior",
    pais: (
      <>
        Ecuador
        <img src="https://eventos.utpl.edu.ec/sites/default/files/images/ecuador.png"></img>
      </>
    ),
    fecha: "Febrero 2018",
    denominacion: "Acreditada",
    alcance: "Nacional",
    duracion: "5 años",
    estado: "Vigente",
  },

  {
    id: 12,
    facultad: "Facultad de Ciencias Exactas y Naturales",
    carrera: "Ingeniería Química",
    modalidad: "Presencial",
    agencia: "Agencia Acreditadora de Chile Acreditación y Calidad",
    pais: (
      <>
        Chile
        <img src="https://eventos.utpl.edu.ec/sites/default/files/images/chile.png"></img>
      </>
    ),

    fecha: "Marzo 2021",
    denominacion: "Acreditada",
    alcance: "Internacional",
    duracion: "5 años",
    estado: "Vigente",
  },
  {
    id: 13,
    facultad: "Facultad de Ciencias Exactas y Naturales",
    carrera: "Ingeniería Química",
    modalidad: "Presencial",
    agencia: "Consejo de Aseguramiento de la Calidad de Educación Superior",
    pais: (
      <>
        Ecuador
        <img src="https://eventos.utpl.edu.ec/sites/default/files/images/ecuador.png"></img>
      </>
    ),
    fecha: "Marzo 2021",
    denominacion: "Acreditada",
    alcance: "Nacional",
    duracion: "5 años",
    estado: "Vigente",
  },
  {
    id: 14,
    facultad: "Facultad de Ciencias Exactas y Naturales",
    carrera: "Bioquímica y Farmacia",
    modalidad: "Presencial",
    agencia: "Agencia Acreditadora de Chile Acreditación y Calidad",
    pais: (
      <>
        Chile
        <img src="https://eventos.utpl.edu.ec/sites/default/files/images/chile.png"></img>
      </>
    ),

    fecha: "Marzo 2021",
    denominacion: "Acreditada",
    alcance: "Internacional",
    duracion: "5 años",
    estado: "Vigente",
  },
  {
    id: 15,
    facultad: "Facultad de Ciencias Exactas y Naturales",
    carrera: "Bioquímica y Farmacia",
    modalidad: "Presencial",
    agencia: "Consejo de Aseguramiento de la Calidad de Educación Superior",
    pais: (
      <>
        Ecuador
        <img src="https://eventos.utpl.edu.ec/sites/default/files/images/ecuador.png"></img>
      </>
    ),
    fecha: "Marzo 2021",
    denominacion: "Acreditada",
    alcance: "Nacional",
    duracion: "5 años",
    estado: "Vigente",
  },
  {
    id: 16,
    facultad: "Facultad de Ciencias Exactas y Naturales",
    carrera: "Ingeniero en Alimentos",
    modalidad: "Presencial",
    agencia: "Agencia Acreditadora de Chile Acreditación y Calidad",
    pais: (
      <>
        Chile{" "}
        <img src="https://eventos.utpl.edu.ec/sites/default/files/images/chile.png"></img>
      </>
    ),

    fecha: "Marzo 2021",
    denominacion: "Acreditada",
    alcance: "Internacional",
    duracion: "5 años",
    estado: "Vigente",
  },
  {
    id: 17,
    facultad: "Facultad de Ciencias Exactas y Naturales",
    carrera: "Ingeniero en Alimentos",
    modalidad: "Presencial",
    agencia: "Consejo de Aseguramiento de la Calidad de Educación Superior",
    pais: (
      <>
        Ecuador
        <img src="https://eventos.utpl.edu.ec/sites/default/files/images/ecuador.png"></img>
      </>
    ),
    fecha: "Marzo 2021",
    denominacion: "Acreditada",
    alcance: "Nacional",
    duracion: "5 años",
    estado: "Vigente",
  },
  {
    id: 18,
    facultad: "Facultad de Ciencias de la Salud",
    carrera: "Medicina",
    modalidad: "Presencial",
    agencia: "Consejo de Aseguramiento de la Calidad de Educación Superior",
    pais: (
      <>
        Ecuador
        <img src="https://eventos.utpl.edu.ec/sites/default/files/images/ecuador.png"></img>
      </>
    ),
    fecha: "Octubre 2019",
    denominacion: "Acreditada",
    alcance: "Nacional",
    duracion: "5 años",
    estado: "Vigente",
  },
  {
    id: 19,
    facultad: "Facultad de Ciencias de la Salud",
    carrera: "Enfermería",
    modalidad: "Presencial",
    agencia: "Agencia Acreditadora de Chile Acreditación y Calidad",
    pais: (
      <>
        Chile
        <img src="https://eventos.utpl.edu.ec/sites/default/files/images/chile.png"></img>
      </>
    ),

    fecha: "",
    denominacion: "",
    alcance: "Internacional",
    duracion: "",
    estado: "En proceso",
  },
];

const datatable3 = [
  {
    id: 1,
    facultad: "Facultad de Ciencias de la Salud",
    posgrado: "Gerencia de Instituciones de Salud",
    modalidad: "a Distancia",
    agencia: "Agencia Acreditadora de Chile Acreditación & Calidad ",
    pais: (
      <>
        Chile
        <img src="https://eventos.utpl.edu.ec/sites/default/files/images/chile.png"></img>
      </>
    ),

    alcance: "Internacional",
    estado: "En proceso",
  },
  {
    id: 2,
    facultad: "Facultad de Ciencias de la Salud",
    posgrado: "Gestión de la Calidad y Auditoría en Salud",
    modalidad: "a Distancia",
    agencia: "Agencia Acreditadora de Chile Acreditación & Calidad ",
    pais: (
      <>
        Chile
        <img src="https://eventos.utpl.edu.ec/sites/default/files/images/chile.png"></img>
      </>
    ),

    alcance: "Internacional",
    estado: "En proceso",
  },
  {
    id: 3,
    facultad: "Facultad de Ciencias de la Salud",
    posgrado: "Análisis Biológico y Diagnóstico de Laboratorio",
    modalidad: "En línea",
    agencia: "Agencia Acreditadora de Chile Acreditación & Calidad ",
    pais: (
      <>
        Chile
        <img src="https://eventos.utpl.edu.ec/sites/default/files/images/chile.png"></img>
      </>
    ),

    alcance: "Internacional",
    estado: "En proceso",
  },
  {
    id: 4,
    facultad: "Facultad de Ciencias Jurídicas y Políticas",
    posgrado: "Derecho, mención Derecho Procesal",
    modalidad: "En línea",
    agencia: "Agencia Acreditadora de Chile Acreditación & Calidad ",
    pais: (
      <>
        Chile
        <img src="https://eventos.utpl.edu.ec/sites/default/files/images/chile.png"></img>
      </>
    ),

    alcance: "Internacional",
    estado: "En proceso",
  },
  {
    id: 5,
    facultad: "Facultad de Ciencias Sociales, Educación y Humanidades",
    posgrado: "Educación, mención Innovación y Liderazgo Educativo",
    modalidad: "En línea",
    agencia: "Agencia Acreditadora de Chile Acreditación & Calidad ",
    pais: (
      <>
        Chile
        <img src="https://eventos.utpl.edu.ec/sites/default/files/images/chile.png"></img>
      </>
    ),

    alcance: "Internacional",
    estado: "En proceso",
  },
  {
    id: 6,
    facultad: "Facultad de Ciencias Sociales, Educación y Humanidades",
    posgrado: "Educación, mención Enseñanza de la Matemática",
    modalidad: "En Línea",
    agencia: "Agencia Acreditadora de Chile Acreditación & Calidad ",
    pais: (
      <>
        Chile
        <img src="https://eventos.utpl.edu.ec/sites/default/files/images/chile.png"></img>
      </>
    ),

    alcance: "Internacional",
    estado: "En proceso",
  },

  {
    id: 7,
    facultad: "Escuela de Desarrollo Empresarial y Social",
    posgrado: "Administración de Empresas, mención Innovación",
    modalidad: "Presencial, A distancia",
    agencia: "Education Quality Accreditation Agency",
    pais: (
      <>
        Perú
        <img src="https://eventos.utpl.edu.ec/sites/default/files/images/peru.png"></img>
      </>
    ),
    alcance: "Internacional",
    estado: "En proceso",
  },
];

function Calidad() {
  let { data, errors } = useContentful(query);
  if (errors)
    return <span>{errors.map((error) => error.message).join(",")}</span>;
  if (!data) return <p>Loading...</p>;

  return (
    <div>
      <div className="margin">
        <Breadcumbs
          content={
            <>
              <NavLink to="/transparencia">Transparencia</NavLink>
              <span class="material-icons">chevron_right</span>
              <NavLink to="/calidad">Calidad</NavLink>
            </>
          }
        ></Breadcumbs>
      </div>
      <BlockLeftImage
        src={data.videoTemplate.imageBlock1.url}
        content={
          <>
            {documentToReactComponents(data.videoTemplate.contentBlock1.json)}
            <a className="button1" href="#posicionamiento">
              <span class="material-icons">chevron_right</span> Posicionamiento
              institucional
            </a>
            <a className="button1" href="#evaluacion">
              <span class="material-icons">chevron_right</span> Evaluación y
              acreditación nacional e internacional
            </a>

            <br></br>
            <Accordion
              title="Documentos institucionales"
              content={
                <>
                  {documentToReactComponents(
                    data.videoTemplate.contentBlock2.json
                  )}
                </>
              }
            ></Accordion>
          </>
        }
      ></BlockLeftImage>

      <div id="posicionamiento" className=" graybg padding">
        <center>
          <h2>
            <b>Rankings y Posicionamiento</b> Institucional
          </h2>
          <p className="width700">
            Nuestro compromiso con la{" "}
            <b>
              excelencia educativa, la investigación y la formación integral{" "}
            </b>
            nos posiciona como una de las mejores universidades del Ecuador,
            ofreciendo a nuestros estudiantes una educación de alta calidad que
            impacta positivamente en la sociedad.
          </p>
        </center>

        <DataFetcherNews url="https://noticias.utpl.edu.ec/parque/servicio-ranking.json" />
        <div className="layout8">
          <h4>
            ¿Necesitas <b>más información?</b>
          </h4>
          <p>📩 dcolindres@utpl.edu.ec </p>
          <p> 📞 07 370 1444 ext. 2279</p>
          <p>Dora Colindres Lozano </p>
          <p>Coordinadora de Posicionamiento y Prestigio Institucional</p>
        </div>
      </div>
      <div className="padding" id="evaluacion">
        <center>
          {" "}
          <h2>
            <b>Evaluación y acreditación</b> nacional e internacional
          </h2>
        </center>

        <div className=" wrapper-evaluacion-institucional wrapper-evaluacion">
          <div className="layout8 icon100">
            <motion.div
              initial={{ y: 200, opacity: 0 }}
              whileInView={{
                y: 0,
                opacity: 1,
                transition: {
                  type: "spring",
                  stiffness: 200,
                },
              }}
            >
              <center>
                <div className="width700 ">
                  {documentToReactComponents(
                    data.videoTemplate.contentBlock3.json
                  )}
                </div>
              </center>
            </motion.div>
            <div className="table-calidad">
              <DataTable columns={columns1} data={datatable1} />
            </div>
          </div>
        </div>

        <div className=" padding2 wrapper-evaluacion">
          <div className="layout9">
            <center>
              <motion.div
                initial={{ y: 200, opacity: 0 }}
                whileInView={{
                  y: 0,
                  opacity: 1,
                  transition: {
                    type: "spring",
                    stiffness: 200,
                  },
                }}
              >
                <div className="width700 ">
                  {documentToReactComponents(
                    data.videoTemplate.contentBlock4.json
                  )}
                </div>
              </motion.div>
            </center>
            <div className="table-calidad">
              <DataTable columns={columns2} data={datatable2} pagination />
            </div>
          </div>
        </div>

        <div className="  wrapper-evaluacion">
          <div className="layout9">
            <center>
              <motion.div
                initial={{ y: 200, opacity: 0 }}
                whileInView={{
                  y: 0,
                  opacity: 1,
                  transition: {
                    type: "spring",
                    stiffness: 200,
                  },
                }}
              >
                <div className="width700 ">
                  {documentToReactComponents(
                    data.videoTemplate.contentBlock5.json
                  )}
                </div>
              </motion.div>
            </center>
            <div className="table-calidad">
              <DataTable columns={columns3} data={datatable3} pagination />
            </div>
          </div>
        </div>

        <div className="padding2">
          <div className="layout8 grayicon">
            <center>
              <h3>
                Acreditadoras <b>nacionales</b>
              </h3>
              <div className="flex-center icon200">
                <div>
                  <a
                    href="https://www.caces.gob.ec"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={caces}></img>
                  </a>
                </div>
              </div>

              <h3>
                Acreditadoras <b>internacionales</b>
              </h3>
              <div className="flex-center icon150">
                <div>
                  <img src={chile}></img>
                </div>

                <div>
                  <img src={equa}></img>
                </div>

                <div>
                  <img src={kalos}></img>
                </div>
              </div>

              <h3>
                {" "}
                Certificaciones <b>internacionales</b>
              </h3>
              <div className="flex-center icon150">
                <div>
                  <img src={acreditae}></img>
                </div>{" "}
              </div>
            </center>
          </div>
        </div>

        <div className="layout8">
          <h4>
            ¿Necesitas <b>más información?</b>
          </h4>
          <p>📩 cglabanda@utpl.edu.ec</p>
          <p> 📞 07 370 1444 ext. 2237</p>
          <p>Cristhian German Labanda</p>
          <p>Especialista de Evaluación Institucional</p>
        </div>
      </div>
    </div>
  );
}

export default Calidad;
