import DataFetcherTemplate from "../../hooks/fetchTemplate";
import BannerGeneral from "../../components/BannerGeneral";
import ModalFixed from "../../components/ModalFixed";
import BlockBackgroundImage from "../../components/BlockBackgroundImage";
import BlockImageCabecera from "../../components/BlockImageCabecera";

import Notification from "../../components/Notification";

import Breadcumbs from "../../components/Breadcumbs";
import { NavLink } from "react-router-dom";

import ConectUs from "../../components/ConectUs";

import ImageGallery from "../../components/ImageGallery";
import PosgradoPagos from "../../components/PosgradoPagos";
import EdesOffer from "../../components/EdesOffer";
import BlockLeftImage from "../../components/BlockLeftImage";
import EdesPagos from "../../components/EdesPagos";

function Edes() {
  const DataDisplay = ({ data }) => (
    <>
      <div>
        {data.map((item, index) => (
          <div>
            <BlockImageCabecera
              src={item.field_portada}
              title={
                <>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.field_texto_portada,
                    }}
                  />
                </>
              }
            ></BlockImageCabecera>
            <div className="relative">
              <Notification
                content={
                  <div
                    className="layout8"
                    dangerouslySetInnerHTML={{
                      __html: item.field_bloque1,
                    }}
                  />
                }
              ></Notification>

              <ModalFixed
                title="Proceso de matrícula 📝"
                color="#5854a7"
                content={
                  <>
                    <div className="width400">
                      <ImageGallery
                        gallery={item.field_gallery1}
                      ></ImageGallery>
                    </div>
                  </>
                }
              ></ModalFixed>
            </div>{" "}
            <Breadcumbs
              content={
                <>
                  <NavLink to="/edes">EDES Escuela de Negocios</NavLink>
                </>
              }
            ></Breadcumbs>
            <div
              className="layout8"
              dangerouslySetInnerHTML={{
                __html: item.field_bloque2,
              }}
            />
            <EdesOffer></EdesOffer>{" "}
            <div className="margin">
              <BlockLeftImage
                src={item.field_imagen4}
                content={
                  <div
                    className="layout8"
                    dangerouslySetInnerHTML={{
                      __html: item.field_bloque4,
                    }}
                  />
                }
              ></BlockLeftImage>

              <BlockBackgroundImage
                src={item.field_imagen3}
                content={
                  <div
                    className="layout8"
                    dangerouslySetInnerHTML={{
                      __html: item.field_bloque3,
                    }}
                  />
                }
              ></BlockBackgroundImage>
            </div>
          </div>
        ))}
      </div>
    </>
  );

  return (
    <>
      <DataFetcherTemplate url="https://utpl.edu.ec/carreras/oferta/servicio-edes.json">
        {(data) => <DataDisplay data={data} />}
      </DataFetcherTemplate>
      <ConectUs
        linkedin="https://www.linkedin.com/company/edesecuador/"
        youtube="https://www.youtube.com/@edesbusinessschool4149"
      ></ConectUs>

      <EdesPagos></EdesPagos>
      <div className="margin">
        <BannerGeneral></BannerGeneral>{" "}
      </div>
    </>
  );
}

export default Edes;
