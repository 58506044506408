import "../css/modal.css";
import { useState } from "react";
import { motion } from "framer-motion";

function ModalFixed(props) {
  const [state, setState] = useState(false);

  const mystyle = {
    backgroundColor: props.color,
  };

  const handleOutsideClick = (e) => {
    if (e.target.classList.contains("wrapper-contentcard")) {
      setState(false);
    }
  };

  return (
    <>
      <motion.div
        initial={{ y: 100, opacity: 0 }}
        animate={{ y: 0, opacity: 1, transition: { type: "spring", stiffness: 200 } }}
      >
        <div
          className="modal-open wrapper-modalfixed"
          style={mystyle}
          onClick={() => setState(true)}
        >
          <p>{props.title}</p>
          <span className="material-icons">arrow_forward_ios</span>
        </div>
      </motion.div>

      {state && (
        <motion.div
          className="wrapper-contentcard"
          initial={{ y: 100, opacity: 0 }}
          animate={{ y: 0, opacity: 1, transition: { type: "spring", stiffness: 200 } }}
          onClick={handleOutsideClick}
        >
          <div className="modal-container">
            <span
              className="material-icons close-modalcard"
              onClick={() => setState(false)}
            >
              close
            </span>
            {props.content}
          </div>
        </motion.div>
      )}
    </>
  );
}

export default ModalFixed;
