import { NavLink } from "react-router-dom";
import Breadcumbs from "../components/Breadcumbs";
import BlockAcademicOffer from "../components/BlockAcademicOffer";
import BlockRightImage from "../components/BlockRightImage";
import BlockLeftImage from "../components/BlockLeftImage";
import DataFetcherTemplate from "../hooks/fetchTemplate";
function Decidesermas() {
  const DataDisplay = ({ data }) => (
    <>
      {data.map((item, index) => (
        <div key={index}>
          <div className="bluebg padding">
            <div
              className="layout8"
              dangerouslySetInnerHTML={{ __html: item.field_texto_portada }}
            />
          </div>{" "}
          <BlockLeftImage
            src={item.field_imagen3}
            content={
              <>
                {" "}
                <div dangerouslySetInnerHTML={{ __html: item.field_bloque3 }} />
              </>
            }
          >
            {" "}
          </BlockLeftImage>
          <BlockRightImage
            src={item.field_imagen2}
            content={
              <>
                {" "}
                <div dangerouslySetInnerHTML={{ __html: item.field_bloque2 }} />
              </>
            }
          ></BlockRightImage>
        </div>
      ))}
    </>
  );

  return (
    <>
      <div className="margin">
        <Breadcumbs
          content={
            <>
              <NavLink to="/decidesermas">Decide ser más</NavLink>
            </>
          }
        ></Breadcumbs>
      </div>
      <div className="layout8">
        <center>
          <h2 className="tag-sermas">
            Te unes al reto de <b>ser más</b>
          </h2>
        </center>
      </div>
      <BlockAcademicOffer></BlockAcademicOffer>

      <DataFetcherTemplate url="https://utpl.edu.ec/carreras/services/servicio-sermas.json">
        {(data) => <DataDisplay data={data} />}
      </DataFetcherTemplate>
    </>
  );
}

export default Decidesermas;
