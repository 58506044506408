import DataFetcherTemplate from "../../hooks/fetchTemplate";
import { NavLink } from "react-router-dom";
import BlockLeftImage from "../../components/BlockLeftImage";
import Block5Columns from "../../components/Block5Columns";
import BlockRightImage from "../../components/BlockRightImage";
import Breadcumbs from "../../components/Breadcumbs";
function AlianzaUPV() {
  const DataDisplay = ({ data }) => (
    <>
      <>
        {data.map((item, index) => (
          <>
            {data.map((item, index) => (
              <>

              
                <div className=" margin">

                <Breadcumbs
                content={
                  <>
                    <NavLink to="/edes">EDES Escuela de negocios</NavLink>
                    <span class="material-icons">chevron_right</span>
                    <NavLink to="/alianza-upv">
                      Alianza UPV
                    </NavLink>
                  </>
                }
              ></Breadcumbs>
                  <center>
                    <div
                      className="width700 icon200"
                      dangerouslySetInnerHTML={{
                        __html: item.field_texto_portada,
                      }}
                    />
                  </center>
                </div>

                <BlockLeftImage
                  src={item.field_imagen2}
                  content={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.field_bloque2,
                      }}
                    />
                  }
                ></BlockLeftImage>

                <div className="padding icon100">
                  <Block5Columns
                    col1={
                      <>
                        <img src={item.field_imagen3_1}></img>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.field_bloque3,
                          }}
                        />
                      </>
                    }
                    col2={
                      <>
                        <img src={item.field_imagen4_1}></img>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.field_bloque4,
                          }}
                        />
                      </>
                    }
                    col3={
                      <>
                        <img src={item.field_imagen5_1}></img>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.field_bloque5,
                          }}
                        />
                      </>
                    }
                    col4={
                      <>
                        <img src={item.field_imagen6_1}></img>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.field_bloque6,
                          }}
                        />
                      </>
                    }
                    col5={
                      <>
                        <img src={item.field_imagen7_1}></img>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.field_bloque7,
                          }}
                        />
                      </>
                    }
                  ></Block5Columns>
                </div>

                <BlockLeftImage
                  src={item.field_imagen8}
                  content={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.field_bloque8,
                      }}
                    />
                  }
                ></BlockLeftImage>

                <BlockRightImage
                  src={item.field_imagen9}
                  content={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.field_bloque9,
                      }}
                    />
                  }
                ></BlockRightImage>
              </>
            ))}
          </>
        ))}
      </>
    </>
  );
  return (
    <>
      {" "}
      <DataFetcherTemplate url="https://utpl.edu.ec/carreras/services/servicio-alianzaupv.json">
        {(data) => <DataDisplay data={data} />}
      </DataFetcherTemplate>
    </>
  );
}

export default AlianzaUPV;
