import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import "../css/news.css";

const DataFetcherNews = ({ url }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const newsWrapperRef = useRef(null); // Referencia al contenedor de noticias

  const handleScroll = (direction) => {
    const wrapper = newsWrapperRef.current;
    wrapper.scrollBy({
      left: direction === "right" ? 300 : -300, // 300px de desplazamiento
      behavior: "smooth", // Desplazamiento suave
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!url) {
          throw new Error("URL del servicio no proporcionada");
        }
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const jsonData = await response.json();
        setData(jsonData);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [url]); // El array vacío significa que este efecto solo se ejecutará una vez al montar el componente

  if (loading) {
    return <div>Cargando...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <center>
        <h4>
          Descubre nuestras <b>últimas noticias</b>
        </h4>

        <div className="gallery-indicators-noticias">
          <span className="material-icons" onClick={() => handleScroll("left")}>
            arrow_back
          </span>
          <span className="material-icons" onClick={() => handleScroll("right")}>
            arrow_forward
          </span>
        </div>
      </center>

      <div className="wrapper-noticias layout9" ref={newsWrapperRef}>
        <div className="flex-noticias padding2">
          {data.map((item, index) => (
            <div  className="item-noticias" key={index}>
              {item.field_multimedia.length > 1 ? (
                <div className="noticias-imagen">
                  <img src={item.field_multimedia} alt="Noticia" />
                </div>
              ) : (
                <div
                  className="noticias-imagen"
                  dangerouslySetInnerHTML={{ __html: item.field_imagen }}
                />
              )}

              <div className="layout9">
                <p className="title">
                  <b>{item.title}</b>
                </p>
                <div className="wrapper-noticias-button">
                <div className="noticias-button" dangerouslySetInnerHTML={{ __html: item.Enlace }} /></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

// Definimos los tipos de las props y requerimos `url`
DataFetcherNews.propTypes = {
  url: PropTypes.string.isRequired,
};

export default DataFetcherNews;
