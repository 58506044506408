import { Route, Routes } from "react-router-dom";
import EspecializacionAlcantarillado from "../../src/newPages/posgrados/EspecializacionAlcantarillado";
import EspecializacionVias from "../../src/newPages/posgrados/EspecializacionVias";
import EspecializacionGeoinformacion from "../../src/newPages/posgrados/EspecializacionGeoinformacion";
import EspecializacionLogistica from "../../src/newPages/posgrados/EspecializacionLogistica";
import EspecializacionTributacion from "../../src/newPages/posgrados/EspecializacionTributacion";
import DoctoradoQuimica from "../../src/newPages/posgrados/DoctoradoQuimica";
import MaestriaAnalisisBiologico from "../../src/newPages/posgrados/MaestriaAnalisisBiologico";
import MaestriaEducacionCurriculo from "../../src/newPages/posgrados/MaestriaEducacionCurriculo";
import MaestriaDatos from "../newPages/posgrados/MaestriaDatos";
import MaestriaContenidosIA from "../newPages/posgrados/MaestriaContenidosIA";

function RoutesPosgrados() {
  return (
    <>
      <Routes>
        {" "}
        <Route
          path="/doctorado-quimica"
          element={<DoctoradoQuimica></DoctoradoQuimica>}
        />
        <Route
          path="/maestria-analisis-biologico"
          element={<MaestriaAnalisisBiologico></MaestriaAnalisisBiologico>}
        />
        <Route
          path="/maestria-educacion-curriculo"
          element={<MaestriaEducacionCurriculo></MaestriaEducacionCurriculo>}
        />
        <Route
          path="/maestria-datos"
          element={<MaestriaDatos></MaestriaDatos>}
        />
         <Route
          path="/maestria-comunicacion-ia"
          element={<MaestriaContenidosIA></MaestriaContenidosIA>}
        />
        <Route
          path="/especializacion-alcantarillado"
          element={
            <EspecializacionAlcantarillado></EspecializacionAlcantarillado>
          }
        />
        <Route
          path="/especializacion-vias"
          element={<EspecializacionVias></EspecializacionVias>}
        />
        <Route
          path="/especializacion-geoinformacion"
          element={
            <EspecializacionGeoinformacion></EspecializacionGeoinformacion>
          }
        />
        <Route
          path="/especializacion-logistica"
          element={<EspecializacionLogistica></EspecializacionLogistica>}
        />
        <Route
          path="/especializacion-tributacion"
          element={<EspecializacionTributacion></EspecializacionTributacion>}
        />
      </Routes>{" "}
    </>
  );
}

export default RoutesPosgrados;
