import "../css/gallery.css";
import { useState, useEffect } from "react";

function ImageGallery({ gallery, id }) {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % gallery.length);
      const element = document.getElementById(`wrappergallery-${id}`);
      if (element) {
        element.scrollLeft = (currentIndex + 1) % gallery.length * element.offsetWidth;
      }
    }, 4000);

    return () => clearInterval(interval);
  }, [gallery.length, id, currentIndex]);

  const handleIndicatorClick = (index) => {
    const element = document.getElementById(`wrappergallery-${id}`);
    if (!element) return;
    element.scrollLeft = index * element.offsetWidth;
    setCurrentIndex(index);
  };

  return (
    <>
      <div className="wrapper-gallery">
        <div id={`wrappergallery-${id}`} className="gallery-container">
          {gallery.map((url, index) => (
            <div className="item-gallery" key={index}>
              <img src={url} alt={`Imagen ${index + 1}`} />
            </div>
          ))}
        </div>
      </div>

      <div className="gallery-indicators">
        {gallery.map((_, index) => (
          <span
            key={index}
            className={`indicator ${index === currentIndex ? "active" : ""}`}
            onClick={() => handleIndicatorClick(index)}
          >
            ●
          </span>
        ))}
      </div>
    </>
  );
}

export default ImageGallery;
