import useContentful from "../hooks/use-contenful";
import "../css/academicoffer.css";
import { useState } from "react";

function EdesVista(props) {
  const [state, setState] = useState(0);

  let key = "0";
  let facultad = "0";
  let query = "";
  let modalidad = "0";

  if (props.modalidad) {
    modalidad = props.modalidad;
  } else {
    modalidad = "0";
  }

  if (props.keyword) {
    key = props.keyword;
  } else {
    key = 0;
  }

  if (props.facultad) {
    facultad = props.facultad;
  } else {
    facultad = 0;
  }

  if (key === 0 && modalidad === "0") {
    query = `query 
    {
     academicProgramsCollection(where:{
       tipoDePrograma:"Edes",
       facultad_contains:"${facultad}",
     }order:[ofertaActual_DESC,nombre_ASC ]){items{
       sys{id}
       nombre
      facultad
      periodo
       duracion
       inicio
       enlaceAPrograma
       keywords
       ofertaActual
       modalidad
       campoconocimientoPosgrado
       
     } 
     }
     }  
    `;
  } else if (key === 0 && modalidad !== "0") {
    query = `query 
      {
       academicProgramsCollection(where:{
        tipoDePrograma:"Edes",
        facultad_contains:"${facultad}",
        modalidad_contains_all:"${modalidad}"
       }order:[ofertaActual_DESC,nombre_ASC ]){items{
         sys{id}
         nombre
        facultad
         duracion
         periodo
         inicio
         enlaceAPrograma
         keywords
         ofertaActual
         modalidad
         campoconocimientoPosgrado
         
       } 
       }
       }  
      `;
  } else if (key !== 0 && modalidad === "0") {
    query = `query 
      {
       academicProgramsCollection(where:{
        tipoDePrograma:"Edes",
        facultad_contains:"${facultad}",
        keywords_contains_all:"${key}"
       }order:[ofertaActual_DESC,nombre_ASC ]){items{
         sys{id}
         nombre
        facultad
         duracion
         periodo
         inicio
         enlaceAPrograma
         keywords
         ofertaActual
         modalidad
         campoconocimientoPosgrado
         
       } 
       }
       }  
      `;
  } else if (key !== 0 && modalidad !== "0") {
    query = `query 
      {
       academicProgramsCollection(where:{
        tipoDePrograma:"Edes",
        facultad_contains:"${facultad}",
        modalidad_contains_all:"${modalidad}"
        keywords_contains_all:"${key}"
       }order:[ofertaActual_DESC,nombre_ASC ]){items{
         sys{id}
         nombre
        facultad
         duracion
         periodo
         inicio
         enlaceAPrograma
         keywords
         ofertaActual
         modalidad
         campoconocimientoPosgrado
         
       } 
       }
       }  
      `;
  }
  function IsInicio(inicio) {
    try {
      if (inicio[1]) {
        return (
          <>
            <b>Inicio de clases:</b>
            <p>{inicio[0]}</p>
            <p>{inicio[1]}</p>
          </>
        );
      } else {
        return (
          <>
            <b>Inicio de clases:</b> {inicio[0]}
          </>
        );
      }
    } catch (error) {
      return "";
    }
  }

  function IsModalidad(modalidad) {
    try {
      if (modalidad[1]) {
        return (
          <>
            <p>
              {" "}
              <b>Modalidad:</b> {modalidad[0]}, {modalidad[1]}
            </p>
          </>
        );
      } else {
        return (
          <>
            <p>
              <b>Modalidad:</b> {modalidad[0]}
            </p>
          </>
        );
      }
    } catch (error) {
      return "";
    }
  }
  function IsPeriodo(periodo) {
    try {
      if (periodo[1]) {
        return (
          <>
            <p>
              <b>Periodo:</b> {periodo[0]}, {periodo[1]}{" "}
            </p>
          </>
        );
      } else {
        return (
          <>
            <p>
              <b>Periodo:</b> {periodo[0]}
            </p>
          </>
        );
      }
    } catch (error) {
      return "";
    }
  }

  function Islink(link) {
    try {
      if (link) {
        return (
          <>
            <div className="wrapper-link">
              <a
                className="link-offer"
                href={link}
                target="_blank"
                rel="noreferrer"
              >
                Ver programa
                <span className="material-icons">arrow_forward_ios </span>
              </a>
            </div>
          </>
        );
      } else {
        return "";
      }
    } catch (error) {
      return "";
    }
  }

  function ViewGrid() {
    setState(0);
  }

  function ViewList() {
    setState(1);
  }

  let { data, errors } = useContentful(query);
  if (errors)
    return <span>{errors.map((error) => error.message).join(",")}</span>;
  if (!data) return <p>Loading...</p>;

  return (
    <>
      <div className="wrapper-view">
        {state === 0 && (
          <div>
            <p onClick={ViewList}>
              Vista de cuadrícula <span class="material-icons">grid_view</span>
            </p>
          </div>
        )}
        {state === 1 && (
          <div>
            <p onClick={ViewGrid}>
              Vista de lista <span class="material-icons">view_list</span>
            </p>
          </div>
        )}
      </div>
      <br></br>
      {state === 0 && (
        <div className="wrapper-academicoffer">
          {data?.academicProgramsCollection.items?.map((info) => (
            <>
              <div key={info.sys.id}>
                <div className="layout8">
                  <h3>{info.nombre}</h3>
                  {IsModalidad(info.modalidad)}
                  <p>
                    <b>Duración:</b> {info.duracion}
                  </p>
                  <p>
                    <b>Campo del conocimiento: </b>
                    {info.campoconocimientoPosgrado}
                  </p>

                  {IsPeriodo(info.periodo)}

                  <p>{IsInicio(info.inicio)}</p>

                  {Islink(info.enlaceAPrograma)}
                </div>
              </div>
            </>
          ))}
        </div>
      )}
      {state === 1 && (
        <div className="wrapper-academicofferlist layout9">
          {data?.academicProgramsCollection.items?.map((info) => (
            <>
              <div key={info.sys.id}>
                <a href={info.enlaceAPrograma} target="_blank" rel="noreferrer">
                  <span class="material-icons">arrow_right_alt</span>{" "}
                  {info.nombre}
                </a>
              </div>
            </>
          ))}
        </div>
      )}
    </>
  );
}

export default EdesVista;
