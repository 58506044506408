import { NavLink } from "react-router-dom";
import Breadcumbs from "../../components/Breadcumbs";
import DataFetcherTemplate from "../../hooks/fetchTemplate";
import BannerGeneral from "../../components/BannerGeneral";
import BlockImageCabecera from "../../components/BlockImageCabecera";
import Accordion from "../../components/Accordion";
import PosgradoPagos from "../../components/PosgradoPagos";
import { PosgradoPostulacionFixed } from "../../components/Pasos";
import ModalFixed from "../../components/ModalFixed";
import ImageGallery from "../../components/ImageGallery";
import BlockLeftImage from "../../components/BlockLeftImage";
import Quote from "../../components/Quote";
import ConectUs from "../../components/ConectUs";
import Block6Columns from "../../components/Block6Columns";
import calendario from "../../img/iconsoferta/calendario.png";
import modalidad from "../../img/iconsoferta/modalidad.png";
import titulo from "../../img/iconsoferta/titulo.png";
import sale from "../../img/iconsoferta/sale.png";
import campo from "../../img/iconsoferta/campo.png";
import inicio from "../../img/iconsoferta/inicio.png";

import BlockRightImage from "../../components/BlockRightImage";
import Block3Columns from "../../components/Block3Columns";

function MaestriaAnalisisBiologico() {
  function brochure(data) {
    if (data) {
      return (
        <>
          <a class="button" target="_blank" rel="noreferrer" href={data}>
            Brochure del programa 📕
          </a>
          <br></br>
        </>
      );
    }
  }
  const DataDisplay = ({ data }) => (
    <>
      <div>
        {data.map((item, index) => (
          <div>
            {" "}
            <BlockImageCabecera
              src={item.field_portada}
              title={
                <div
                  dangerouslySetInnerHTML={{
                    __html: item.field_texto_portada,
                  }}
                />
              }
            ></BlockImageCabecera>
            <div className="graybg">
              <Breadcumbs
                content={
                  <>
                    <NavLink to="/posgrados">Posgrados</NavLink>
                    <span class="material-icons">chevron_right</span>
                    <NavLink to="/maestria-analisis-biologico">
                      Maestría en Análisis Biológico y Diagnóstico de
                      Laboratorio
                    </NavLink>
                  </>
                }
              ></Breadcumbs>
              <div className="padding oferta-icons">
                <Block6Columns
                  col1={
                    <>
                      <img src={titulo}></img>
                      <p>
                        <b>Título</b>
                      </p>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: item.field_posgrado_titulo,
                        }}
                      />
                    </>
                  }
                  col2={
                    <>
                      <img src={modalidad}></img>
                      <p>
                        <b>Modalidad</b>
                      </p>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: item.field_modalidad,
                        }}
                      />
                    </>
                  }
                  col3={
                    <>
                      <img src={calendario}></img>
                      <p>
                        <b>Duración</b>
                      </p>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: item.field_posgrado_duracion,
                        }}
                      />
                    </>
                  }
                  col4={
                    <>
                      <img src={campo}></img>
                      <p>
                        <b>Campo amplio</b>
                      </p>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: item.field_posgrado_campo,
                        }}
                      />
                    </>
                  }
                  col5={
                    <>
                      <img src={sale}></img>
                      <p>
                        <b>Precio</b>
                      </p>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: item.field_precio,
                        }}
                      />
                    </>
                  }
                  col6={
                    <>
                      <img src={inicio}></img>
                      <p>
                        <b>Fecha de inicio</b>
                      </p>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: item.field_inicio,
                        }}
                      />
                    </>
                  }
                ></Block6Columns>
              </div>
            </div>
            <div className=" margin ">
              <div className="relative">
                <div className="layout8 "> {brochure(item.field_brochure)}</div>
                <div
                  className="layout8"
                  dangerouslySetInnerHTML={{
                    __html: item.field_pos_resolucion,
                  }}
                />{" "}
                <ModalFixed
                  title="Jhuliana Herrera, UTPL Alumni, gana el XV Premio La Rábida 👩🏻‍🔬🔬"
                  content={
                    <>
                      <iframe
                        width="100%"
                        height="400"
                        src="https://www.youtube.com/embed/lVFXy69KQ70?si=D4RBL9Mj2Vxsd0Xp"
                        allowfullscreen
                      ></iframe>
                    </>
                  }
                ></ModalFixed>
              </div>
              <div
                className="layout8"
                dangerouslySetInnerHTML={{
                  __html: item.field_posgrado_ingreso,
                }}
              />{" "}
            </div>
            <Quote
              content={
                <>
                  {" "}
                  <p
                    dangerouslySetInnerHTML={{
                      __html: item.field_posgrado_atributo,
                    }}
                  />
                </>
              }
            ></Quote>
            <BlockRightImage
              src={item.field_posgrado_imagenegreso}
              content={
                <>
                  {" "}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.field_posgrado_egreso,
                    }}
                  />
                </>
              }
            ></BlockRightImage>{" "}
            <div className="padding2 layout6">
              {" "}
              <Accordion
                title="Malla curricular"
                content={
                  <>
                    {" "}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.field_posgrado_malla,
                      }}
                    />{" "}
                  </>
                }
              ></Accordion>
            </div>
            <div className="relative">
              <Quote
                content={
                  <>
                    {" "}
                    <p
                      dangerouslySetInnerHTML={{
                        __html: item.field_posgrado_atributo2,
                      }}
                    />
                  </>
                }
              ></Quote>{" "}
              <div className="padding2"></div>
              <ModalFixed
                title="El testimonio de Katherine Garcés: su experiencia en la maestría. 💙🔬"
                content={
                  <>
                    <iframe
                      src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Futplposgrados%2Fvideos%2F927009971928801%2F&show_text=false&width=560&t=0"
                      width="100%"
                      height="400"
                    ></iframe>
                  </>
                }
              ></ModalFixed>
            </div>
            <div className=" relative ">
              <div>
                <BlockLeftImage
                  src={item.field_imagen_requisitos}
                  content={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.field_posgrado_requisitos,
                      }}
                    />
                  }
                ></BlockLeftImage>{" "}
              </div>
              <PosgradoPostulacionFixed></PosgradoPostulacionFixed>
            </div>
            <div className="flex">
              <Quote
                content={
                  <>
                    {" "}
                    <p
                      dangerouslySetInnerHTML={{
                        __html: item.field_posgrado_atributo3,
                      }}
                    />
                  </>
                }
              ></Quote>{" "}
              <div className="layout8 padding2 width400">
                <ImageGallery gallery={item.field_gallery}></ImageGallery>
              </div>
            </div>
            <div className="bluebg padding">
              <div className="layout8">
                <center>
                  {" "}
                  <h2>
                    ¿Qué <b>nos diferencia?</b>
                  </h2>
                </center>
              </div>

              <Block3Columns
                col1={
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.field_posgrado_diferencia,
                    }}
                  />
                }
                col2={
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.field_posgrado_diferencia2,
                    }}
                  />
                }
                col3={
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.field_posgrado_diferencia3,
                    }}
                  />
                }
              ></Block3Columns>
              <br></br>
            </div>
            <div className="relative bluebg">
              <ModalFixed
                color="#27a4fd"
                title="Conviértete en experto en Análisis biológico y Diagnóstico de Laboratorio 🤩🔬"
                content={
                  <>
                    <iframe
                      width="100%"
                      height="400%"
                      src="https://www.youtube.com/embed/UIDKUMT_SXU?si=QkqsTfMVhM55NZEI"
                      allowfullscreen
                    ></iframe>
                  </>
                }
              ></ModalFixed>
            </div>
            <div className="wrapper-docente padding ">
              <div
                className="layout6"
                dangerouslySetInnerHTML={{
                  __html: item.field_texto_docente,
                }}
              />

              <div
                className="flex-center"
                dangerouslySetInnerHTML={{
                  __html: item.field_docente,
                }}
              />
            </div>
          </div>
        ))}
      </div>
    </>
  );

  return (
    <>
      <DataFetcherTemplate url="https://www.utpl.edu.ec/maestrias/services/maestria-analisis.json">
        {(data) => <DataDisplay data={data} />}
      </DataFetcherTemplate>
      <div className="padding2">
        <PosgradoPagos></PosgradoPagos>
      </div>
      <ConectUs
        lema="Descubre contenido exclusivo, mantente al tanto de las últimas novedades y únete a nuestra comunidad de estudiantes de posgrado."
        instagram="https://www.instagram.com/utplposgrados/"
        facebook="https://www.facebook.com/utplposgrados"
      ></ConectUs>
      <BannerGeneral></BannerGeneral>
    </>
  );
}

export default MaestriaAnalisisBiologico;
