import { useState } from "react";
import BlockImageCabecera from "../../components/BlockImageCabecera";

import "../../css/reingreso.css";
import img from "../../img/reingreso/retoma2.jpg";
import Block2ColumnsText from "../../components/Block2ColumnsText";
import icon1 from "../../img/reingreso/exito.gif";
import icon2 from "../../img/reingreso/global.gif";

function Reingreso() {
  const [selectedOption, setSelectedOption] = useState(null);

  const options = [
    {
      id: "online",
      label: "Hace un ciclo",
      description: (
        <div>
          <p>🎓 Si abandonaste tus estudios hace un ciclo:</p>
          <p></p>
          <h2 className="tag3-retoma">✅ Modalidad a distancia y en línea</h2>
          <h2 className="tag2-retoma">12% de descuento</h2>
          <h2 className="tag3-retoma">
            {" "}
            en tu matrícula hasta el 1 de abril. 🎓✨
          </h2>
          <h2 className="tag1-retoma">10% de descuento adicional</h2>
          <h2 className="tag3-retoma"> si postulas a una Beca Convenio</h2>
          <a
            class="link-with-arrow"
            target="_blank"
            href="https://becas.utpl.edu.ec/convenio"
          >
            Conoce los requisitos
          </a>

          <div className="margin">
            {" "}
            <h2 className="tag3-retoma">✅ Modalidad presencial</h2>
            <h2 className="tag2-retoma">8% de descuento</h2>
            <h2 className="tag3-retoma"> en tu matrícula. 🎓✨</h2>
          </div>
        </div>
      ),
    },

    {
      id: "ects",
      label: "De 1 a 10 años",
      description: (
        <div>
          <p>🎓 Si abandonaste tus estudios entre 1 a 10 años:</p>
          <h2 className="tag3-retoma">✅ Modalidad a distancia y en línea</h2>
          <h2 className="tag3-retoma">✅ Modalidad presencial</h2>
          <h2 className="tag2-retoma">50% de descuento</h2>
          <h2 className="tag3-retoma"> en tu matrícula. 🎓✨</h2>
        </div>
      ),
    },
  ];

  return (
    <div className="margin">
      <BlockImageCabecera
        src={img}
        title={
          <>
            <h2 className="tag3-retoma">
              ¡Convierte en realidad el sueño de obtener tu{" "}
              <b>título profesional!</b>
            </h2>
          </>
        }
      ></BlockImageCabecera>
      <div>
        <div className="bluebg padding2">
          <center>
            <h3>Aprovecha nuestros descuentos y beneficios exclusivos</h3>

            <h2 className="width700">
              📢 Cuéntanos, <b>¿hace cuánto tiempo</b> abandonaste tus estudios?
            </h2>
            <div className="content-select">
              <select
                value={selectedOption}
                onChange={(e) => setSelectedOption(e.target.value)}
              >
                <option value="" disabled selected>
                  Elige una opción
                </option>
                {options.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </center>
        </div>
        <div className="layout6">
          {selectedOption && (
            <div className="padding2">
              <p>
                {
                  options.find((option) => option.id === selectedOption)
                    ?.description
                }
              </p>
            </div>
          )}
        </div>
        <div className=" padding2">
          <center>
            <h3 className="width700">
              <b>¡No dejes pasar esta oportunidad</b> para retomar tu camino
              académico! 🚀
            </h3>
            <a
              className="button"
              href="https://studentssb-prod.srv.utpl.edu.ec/StudentSelfService"
              target="_blank"
              rel="noreferrer"
            >
              Matricúlate ahora
            </a>
          </center>
        </div>

        <div className="padding2">
          <Block2ColumnsText
            col1={
              <>
                <div className="icon100">
                  <img src={icon1} alt="utpl"></img>
                </div>
                <h3>
                  Recibirás el apoyo de un <b>consejero estudiantil</b>, quien
                  te guiará y acompañará a lo largo de toda tu carrera hasta
                  obtener tu título profesional. 🫂
                </h3>
              </>
            }
            col2={
              <>
                {" "}
                <div className="icon100">
                  <img src={icon2} alt="utpl"></img>
                </div>
                <h3>
                  Podrás acceder a oportunidades globales, ya que tendrás la
                  posibilidad de <b> validar tu título en EE.UU. </b> 🌎
                </h3>{" "}
              </>
            }
          ></Block2ColumnsText>
        </div>

        <div className="graybg padding2">
          <center>
            <h2>¿Necesitas ayuda?</h2>
            <h2 className="tag3-retoma">
              {" "}
              Un asesor está listo para ayudarte. Conéctate con nosotros en{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://utpl.edu.ec/zoom"
              >
                <b>utpl.edu.ec/zoom 📹</b>
              </a>
            </h2>
          </center>
        </div>
      </div>
    </div>
  );
}

export default Reingreso;
