import { useState } from "react";
import "../css/accordion.css";

function Accordion(props) {
  const [isActive, setIsActive] = useState(false);

  const toggleAccordion = () => {
    setIsActive(!isActive);
  };

  return (
    <div className="wrapper-content">
      <div className={`accordion ${isActive ? "active" : ""}`}>
        <div className="accordion-header" onClick={toggleAccordion}>
          <div className="accordion-title">
            {props.title}
          </div>
          <span className="material-icons">
            {isActive ? "remove" : "add"}
          </span>
        </div>
      </div>

      <div
        className={`accordion-content ${isActive ? "show" : ""}`}
        style={{
          maxHeight: isActive ? "max-content" : "0",
          transition: "max-height 0.4s ease",
          overflow: isActive ? "hidden": "auto",
        }}
      >
        <div className="content-inner">{props.content}</div>
      </div>
    </div>
  );
}

export default Accordion;
