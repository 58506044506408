import { useState } from "react";
import "../css/fixednotification.css";
import { motion } from "framer-motion";

function FixedNotification(props) {
  const [state, setState] = useState(true);

  function handleClick() {
    setState(false);
  }
  return (
    <>
      {state && (
        <motion.div
          className="fixed-notification"
          initial={{ opacity: 0, x: -50 }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              type: "spring",
              stiffness: 200,
              delay: 3,
            },
          }}
        >
          <div>
            <a target="_blank" rel="noreferrer" href={props.link}>
              {props.content}
            </a>
          </div>

          <div className="fixed-notification-close" onClick={handleClick}>
            <span className="material-icons">close</span>
          </div>
        </motion.div>
      )}
    </>
  );
}

export default FixedNotification;
