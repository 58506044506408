import { NavLink } from "react-router-dom";
import Breadcumbs from "../components/Breadcumbs";
import DataFetcherTemplate from "../hooks/fetchTemplate";
import BannerGeneral from "../components/BannerGeneral";
import ModalFixed from "../components/ModalFixed";
import Block3Columns from "../components/Block3Columns";
import BlockRightImage from "../components/BlockRightImage";
import BlockLeftImage from "../components/BlockLeftImage";
import FixedHeader from "../components/FixedHeader";
import Modal from "../components/Modal";
import Block2ColumnsText from "../components/Block2ColumnsText";
import Block4ColumnsText from "../components/Block4ColumnsText";
import BlockBackgroundImage from "../components/BlockBackgroundImage";
import Notification from "../components/Notification";

function AcercadePlan() {
  const DataDisplay = ({ data }) => (
    <>
      <>
        {data.map((item, index) => (
          <>
            <FixedHeader
              src={item.field_portada}
              span0={
                <>
                  Audacia <b>creativa</b>
                </>
              }
              span3="para transformar "
              span4="vidas y contextos"
            ></FixedHeader>
            <div className="bluebg">
              <Breadcumbs
                content={
                  <>
                    <NavLink to="/acercade">Acerca de</NavLink>
                  </>
                }
              ></Breadcumbs>
            </div>
            <Notification
              content={
                <div
                  dangerouslySetInnerHTML={{
                    __html: item.field_texto_portada,
                  }}
                />
              }
            ></Notification>
            <BlockLeftImage
              src={item.field_imagen1}
              content={
                <>
                  {" "}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.field_bloque1,
                    }}
                  />
                  <Modal
                    title={
                      <>
                       Ver mensaje
                      </>
                    }
                    content={
                      <div className="graybg padding">
                        {" "}
                        <div
                          className="layout9 "
                          dangerouslySetInnerHTML={{
                            __html: item.field_bloque2,
                          }}
                        />
                      </div>
                    }
                  ></Modal>
                </>
              }
            ></BlockLeftImage>
            <div className="graybg padding ">
              <Block2ColumnsText
                col1={
                  <>
                    <center>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.field_bloque3,
                        }}
                      />
                    </center>
                  </>
                }
                col2={
                  <>
                    {" "}
                    <center>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.field_bloque4,
                        }}
                      />{" "}
                    </center>
                  </>
                }
              ></Block2ColumnsText>
            </div>
            <BlockRightImage
              src={item.field_imagen6}
              content={
                <>
                  {" "}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.field_bloque5,
                    }}
                  />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.field_bloque6,
                    }}
                  />
                </>
              }
            ></BlockRightImage>
            <div className=" relative graybg">
              <BlockBackgroundImage
                src={item.field_imagen7}
                content={
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.field_bloque7,
                    }}
                  />
                }
              ></BlockBackgroundImage>
              <ModalFixed
                title="Conoce nuestra historia 💙"
                content={
                  <>
                    <iframe
                      allowfullscreen=""
                      frameborder="0"
                      height="700"
                      mozallowfullscreen=""
                      src="https://cdn.knightlab.com/libs/timeline3/latest/embed/index.html?source=15c2kS_nfTkn7O4XqbtO6q7sY_tMirfPwyMp1Jb6bcpA&amp;font=Bitter-Raleway&amp;lang=en&amp;initial_zoom=2&amp;height=650"
                      webkitallowfullscreen=""
                      width="100%"
                    ></iframe>
                  </>
                }
                color="#1366a0"
              ></ModalFixed>{" "}
            </div>{" "}
            <div className="relative">
              <div className=" padding ">
                <div className="layut8">
                  {" "}
                  <center>
                    <h2>Líneas estratégicas</h2>
                    <br></br>
                  </center>{" "}
                </div>

                <div className="lineas-estrategicas">
                  <Block4ColumnsText
                    col1={
                      <>
                        <div className="icon100">
                          <img src={item.field_imagen8} alt="utpl"></img>
                        </div>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.field_bloque8,
                          }}
                        />
                        <Modal
                          title={
                            <>
                          Más información
                            </>
                          }
                          content={
                            <div className="graybg padding2">
                              <div
                                className="layout8"
                                dangerouslySetInnerHTML={{
                                  __html: item.field_bloque12,
                                }}
                              />
                            </div>
                          }
                        ></Modal>
                      </>
                    }
                    col2={
                      <>
                        <div className="icon100">
                          <img src={item.field_imagen9} alt="utpl"></img>
                        </div>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.field_bloque9,
                          }}
                        />

                        <Modal
                          title={
                            <>
                             Más información
                            </>
                          }
                          content={
                            <div className="graybg padding2">
                              <div
                                className="layout8"
                                dangerouslySetInnerHTML={{
                                  __html: item.field_bloque13,
                                }}
                              />
                            </div>
                          }
                        ></Modal>
                      </>
                    }
                    col3={
                      <>
                        <div className="icon100">
                          <img src={item.field_imagen10} alt="utpl"></img>
                        </div>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.field_bloque10,
                          }}
                        />
                        <Modal
                          title={
                            <>
                          Más información
                            </>
                          }
                          content={
                            <div className="graybg padding2">
                              <div
                                className="layout8"
                                dangerouslySetInnerHTML={{
                                  __html: item.field_bloque14,
                                }}
                              />
                            </div>
                          }
                        ></Modal>
                      </>
                    }
                    col4={
                      <>
                        <div className="icon100">
                          <img src={item.field_imagen11} alt="utpl"></img>
                        </div>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.field_bloque11,
                          }}
                        />
                        <Modal
                          title={
                            <>
                             Más información
                            </>
                          }
                          content={
                            <div className="graybg padding2">
                              <div
                                className="layout8"
                                dangerouslySetInnerHTML={{
                                  __html: item.field_bloque15,
                                }}
                              />
                            </div>
                          }
                        ></Modal>
                      </>
                    }
                  ></Block4ColumnsText>

                  <br></br>
                </div>
              </div>
              <ModalFixed
                title="Historia de conformación de nuestras carreras 🧩"
                content={
                  <>
                    <iframe
                      allowfullscreen=""
                      frameborder="0"
                      height="700"
                      mozallowfullscreen=""
                      src="https://cdn.knightlab.com/libs/timeline3/latest/embed/index.html?source=1j76kXBC5SXwYKugt-oR6MTauJSaf06VlRkQAjCoUHG8&amp;font=Bitter-Raleway&amp;lang=en&amp;initial_zoom=2&amp;height=650"
                      webkitallowfullscreen=""
                      width="100%"
                    ></iframe>
                  </>
                }
                color="#1366a0"
              ></ModalFixed>{" "}
            </div>
            <div className="pedi-informacion padding">
              <div className="layout6">
                <center>
                  {" "}
                  <h2>
                    ¿Necesitas <b>más información? </b>
                  </h2>
                  <h3>
                    {" "}
                    Para conocer más sobre la estrategia, hacer sugerencias o
                    pedir información escríbenos a <b>📩pedi@utpl.edu.ec.</b>
                  </h3>
                </center>
              </div>
            </div>
          </>
        ))}
      </>
    </>
  );

  return (
    <>
      <DataFetcherTemplate url="https://utpl.edu.ec/carreras/services/servicio-planestrategico.json">
        {(data) => <DataDisplay data={data} />}
      </DataFetcherTemplate>
      <div className="margin"></div>

      <BannerGeneral></BannerGeneral>
    </>
  );
}

export default AcercadePlan;
