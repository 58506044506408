import { NavLink } from "react-router-dom";
import Breadcumbs from "../../components/Breadcumbs";
import DataFetcherTemplate from "../../hooks/fetchTemplate";
import { motion } from "framer-motion";
import BlockRightImage from "../../components/BlockRightImage";
import Accordion from "../../components/Accordion";
import Block4Columns from "../../components/Block4ColumnsText";
import ModalFixed from "../../components/ModalFixed";
import BlockLeftImage from "../../components/BlockLeftImage";
import BlockImageCabecera from "../../components/BlockImageCabecera";
import ImageGallery from "../../components/ImageGallery";
import BannerGeneral from "../../components/BannerGeneral";
import evaluacion from "../../img/logosingles/evaluacion.png";
import certificado from "../../img/logosingles/certificado.png";
import titulos from "../../img/logosingles/titulos.png";
import instituciones from "../../img/logosingles/instituciones.png";


function SegundaLengua() {
  const DataDisplay = ({ data }) => (
    <>
      <div>
        {data.map((item, index) => (
          <div key={index}>
            <div className="margin">
              <BlockImageCabecera
                src={item.field_portada}
                title={
                  <>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.field_texto_portada,
                      }}
                    />
                  </>
                }
              ></BlockImageCabecera>
            </div>
            <div className="bluebg">
              <Breadcumbs
                content={
                  <>
                    <NavLink to="/segunda-lengua">Segunda Lengua</NavLink>
                  </>
                }
              ></Breadcumbs>

              <center>
                <div className="layout8">
                  <div className="width500">
                    <div
                      dangerouslySetInnerHTML={{ __html: item.field_bloque1 }}
                    />
                  </div>
                </div>
              </center>
              <div className="segunda-lengua">
                <Block4Columns
                  col1={
                    <a href="#examen" className="icon20">
                      <center>
                        <img src={evaluacion} alt="utpl"></img>
                      </center>
                      <h4>Rendir un examen de ubicacion</h4>
                      <motion.div
                        className="icons"
                        initial={{ y: 10 }}
                        animate={{ y: 0, opacity: 0.8 }}
                        transition={{ type: "spring", repeat: Infinity }}
                      >
                        <span className="material-icons">
                          keyboard_arrow_down
                        </span>
                      </motion.div>
                    </a>
                  }
                  col2={
                    <a href="#certificados" className="icon20">
                      <center>
                        <img src={certificado} alt="utpl"></img>
                      </center>
                      <h4>Reconocer certificados de otras instituciones</h4>
                      <motion.div
                        className="icons"
                        initial={{ y: 10 }}
                        animate={{ y: 0, opacity: 0.8 }}
                        transition={{ type: "spring", repeat: Infinity }}
                      >
                        <span className="material-icons">
                          keyboard_arrow_down
                        </span>
                      </motion.div>
                    </a>
                  }
                  col3={
                    <a href="#titulos">
                      <div className="icon20">
                        <center>
                          <img src={titulos} alt="utpl"></img>
                        </center>
                      </div>
                      <h4>Homologar títulos de tercer y cuarto nivel </h4>
                      <motion.div
                        className="icons"
                        initial={{ y: 10 }}
                        animate={{ y: 0, opacity: 0.8 }}
                        transition={{ type: "spring", repeat: Infinity }}
                      >
                        <span className="material-icons">
                          keyboard_arrow_down
                        </span>
                      </motion.div>
                    </a>
                  }
                  col4={
                    <a href="#instituciones" className="icon20">
                      <center>
                        <img src={instituciones} alt="utpl"></img>
                      </center>
                      <h4>
                        Prepararte en instituciones externas aliadas a la UTPL
                      </h4>
                      <motion.div
                        className="icons"
                        initial={{ y: 10 }}
                        animate={{ y: 0, opacity: 0.8 }}
                        transition={{ type: "spring", repeat: Infinity }}
                      >
                        <span className="material-icons">
                          keyboard_arrow_down
                        </span>
                      </motion.div>
                    </a>
                  }
                ></Block4Columns>
              </div>
            </div>

            <div id="examen">
              <div className="relative">
                <BlockLeftImage
                  src={item.field_imagen2}
                  content={
                    <>
                      <div className="icon20">
                        <img src={evaluacion} alt="utpl"></img>
                      </div>
                      <div
                        dangerouslySetInnerHTML={{ __html: item.field_bloque2 }}
                      />
                    </>
                  }
                ></BlockLeftImage>
                <ModalFixed
                  color="#4e975b"
                  title="Proceso de inscripción 📝"
                  content={
                    <div className="width400">
                      <ImageGallery
                        gallery={item.field_gallery1}
                      ></ImageGallery>
                    </div>
                  }
                ></ModalFixed>
              </div>
              <div className="relative text-column">
                <div className="content">
                  <div className="layout8 padding2 ">
                    <div
                      dangerouslySetInnerHTML={{ __html: item.field_bloque3 }}
                    />
                  </div>
                </div>

                <ModalFixed
                  color="#4e975b"
                  title="Proceso de registro ✔"
                  content={
                    <div className="width400">
                      <ImageGallery
                        gallery={item.field_gallery2}
                      ></ImageGallery>
                    </div>
                  }
                ></ModalFixed>
              </div>
            </div>

            <div className="graybg" id="certificados">
              <div className="relative">
                <BlockRightImage
                  src={item.field_imagen4}
                  content={
                    <>
                      {" "}
                      <div className="icon20">
                        <img src={certificado} alt="utpl"></img>
                      </div>
                      <div
                        dangerouslySetInnerHTML={{ __html: item.field_bloque4 }}
                      />
                      <Accordion
                        title="Certificados de Lenguas Ancestrales y Lenguas de Señas"
                        content={
                          <>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: item.field_bloque5,
                              }}
                            />
                          </>
                        }
                      ></Accordion>
                      <Accordion
                        title="Certificados nacionales e internacionales"
                        content={
                          <>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: item.field_bloque6,
                              }}
                            />
                          </>
                        }
                      ></Accordion>
                      <Accordion
                        title="Certificados de instituciones de educación superior"
                        content={
                          <>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: item.field_bloque7,
                              }}
                            />
                          </>
                        }
                      ></Accordion>
                      <br></br>
                      <a
                        className="button"
                        href=" https://studentssb-prod.srv.utpl.edu.ec/StudentSelfService/ssb/studentCommonDashboard"
                      >
                        Solicitar homologacion
                      </a>
                    </>
                  }
                ></BlockRightImage>
                <ModalFixed
                  color="#6f56c1"
                  title="Proceso de homologación ✍🏼️"
                  content={
                    <div className="width400">
                      <ImageGallery
                        gallery={item.field_gallery3}
                      ></ImageGallery>
                    </div>
                  }
                ></ModalFixed>{" "}
              </div>
            </div>

            <div id="titulos" className="relative">
              <BlockLeftImage
                src={item.field_imagen8}
                content={
                  <>
                    <div className="icon20">
                      <img src={titulos} alt="utpl"></img>

                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.field_bloque8,
                        }}
                      />
                    </div>
                  </>
                }
              ></BlockLeftImage>
              <ModalFixed
                color="#186150"
                title="Proceso de homologación ✍🏼️"
                content={
                  <div className="width400">
                    {" "}
                    <ImageGallery gallery={item.field_gallery4}></ImageGallery>
                  </div>
                }
              ></ModalFixed>{" "}
            </div>

            <div id="instituciones" className="relative graybg ">
              <div className="layout8 padding">
                <div className="icon20">
                  <img src={instituciones} alt="utpl"></img>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.field_bloque9,
                    }}
                  />
                </div>

                <div className=" layout8 ">
                  <Accordion
                    title="Idioma Inglés"
                    content={
                      <div className="icon100">
                        <div>
                          <BlockLeftImage
                            src={item.field_imagen10}
                            content={
                              <>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: item.field_bloque10,
                                  }}
                                />
                              </>
                            }
                          ></BlockLeftImage>
                        </div>

                        <div>
                        <BlockRightImage
                            src={item.field_imagen11}
                            content={
                              <>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: item.field_bloque11,
                                  }}
                                />
                              </>
                            }
                          ></BlockRightImage>
                          <div>
                          <BlockLeftImage
                            src={item.field_imagen12}
                            content={
                              <>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: item.field_bloque12,
                                  }}
                                />
                              </>
                            }
                          ></BlockLeftImage>
                          </div>
                        </div>
                      </div>
                    }
                  ></Accordion>
                  <Accordion
                    title="Idioma Frances"
                    content={
                      <div className="icon100">
                        <BlockLeftImage
                            src={item.field_imagen13}
                            content={
                              <>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: item.field_bloque13,
                                  }}
                                />
                              </>
                            }
                          ></BlockLeftImage>
                      </div>
                    }
                  ></Accordion>
                  <Accordion
                    title="Idioma Italiano"
                    content={
                      <div className="icon100">
                         <BlockLeftImage
                            src={item.field_imagen14}
                            content={
                              <>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: item.field_bloque14,
                                  }}
                                />
                              </>
                            }
                          ></BlockLeftImage>
                      </div>
                    }
                  ></Accordion>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );

  return (
    <>
      <DataFetcherTemplate url="https://utpl.edu.ec/carreras/services/servicio-segundalengua.json">
        {(data) => <DataDisplay data={data} />}
      </DataFetcherTemplate>
      <BannerGeneral></BannerGeneral>
    </>
  );
}

export default SegundaLengua;
