import Modal from "./Modal";
import Gallery from "./Gallery";
import ModalFixed from "./ModalFixed";

export function DistanciaNuevos() {
  const items = [
    {
      title: "utpl",
      url: "https://utpl.edu.ec/recursos/pasos/distancia/nuevos/portada.jpg",
    },
    {
      title: "utpl",
      url: "https://utpl.edu.ec/recursos/pasos/distancia/nuevos/paso1.jpg",
    },
    {
      title: "utpl",
      url: "https://utpl.edu.ec/recursos/pasos/distancia/nuevos/paso2.jpg",
    },
    {
      title: "utpl",
      url: "https://utpl.edu.ec/recursos/pasos/distancia/nuevos/paso3.jpg",
    },
    {
      title: "utpl",
      url: "https://utpl.edu.ec/recursos/pasos/distancia/nuevos/paso4.jpg",
    }
  ];

  return (
    <Modal
      title="Revisa el proceso de matrícula para estudiantes nuevos 📱"
      content={
        <>
        <div className="width400">
          <Gallery gallery={items} width="400"></Gallery></div>
        </>
      }
    ></Modal>
  );
}

export function DistanciaNuevosFixed() {
  const items = [
    {
      title: "utpl",
      url: "https://utpl.edu.ec/recursos/pasos/distancia/nuevos/portada.jpg",
    },
    {
      title: "utpl",
      url: "https://utpl.edu.ec/recursos/pasos/distancia/nuevos/paso1.jpg",
    },
    {
      title: "utpl",
      url: "https://utpl.edu.ec/recursos/pasos/distancia/nuevos/paso2.jpg",
    },
    {
      title: "utpl",
      url: "https://utpl.edu.ec/recursos/pasos/distancia/nuevos/paso3.jpg",
    },
    {
      title: "utpl",
      url: "https://utpl.edu.ec/recursos/pasos/distancia/nuevos/paso4.jpg",
    }
  ];

  return (
    <ModalFixed
    color="#9184ec"
      title="Proceso de matrícula"
      content={
        <> <div className="width400">
          <Gallery gallery={items} width="400"></Gallery></div>
        </>
      }
    ></ModalFixed>
  );
}


export function DistanciaContinuos() {
  const items = [
    {
      title: "utpl",
      url: "https://utpl.edu.ec/recursos/pasos/distancia/continuos/portada.png",
    },
    {
      title: "utpl",
      url: "https://utpl.edu.ec/recursos/pasos/distancia/continuos/paso1.png",
    },
    {
      title: "utpl",
      url: "https://utpl.edu.ec/recursos/pasos/distancia/continuos/paso2.png",
    },
    {
      title: "utpl",
      url: "https://utpl.edu.ec/recursos/pasos/distancia/continuos/paso3.png",
    },
    {
      title: "utpl",
      url: "https://utpl.edu.ec/recursos/pasos/distancia/continuos/paso4.png",
    },
    {
      title: "utpl",
      url: "https://utpl.edu.ec/recursos/pasos/distancia/continuos/paso5.png",
    },
  ];

  return (
    <Modal
      title="Revisa el proceso de matrícula para estudiantes continuos 📱"
      content={
        <> <div className="width400">
          <Gallery gallery={items} width="400"></Gallery></div>
        </>
      }
    ></Modal>
  );
}

export function PresencialInscripcion() {
  const items = [
    {
      title: "utpl",
      url: "https://utpl.edu.ec/recursos/pasos/presencial/inscripcion/portada.jpg",
    },
    {
      title: "utpl",
      url: "https://utpl.edu.ec/recursos/pasos/presencial/inscripcion/paso1.jpg",
    },
    {
      title: "utpl",
      url: "https://utpl.edu.ec/recursos/pasos/presencial/inscripcion/paso2.jpg",
    },
    {
      title: "utpl",
      url: "https://utpl.edu.ec/recursos/pasos/presencial/inscripcion/paso3.png",
    },
  ];

  return (
    <Modal
      title="Revisa el proceso de inscripción👌"
      content={
        <> <div className="width400">
          <Gallery gallery={items} width="400"></Gallery></div>
        </>
      }
    ></Modal>
  );
}

export function PresencialInscripcionFixed() {
  const items = [
    {
      title: "utpl",
      url: "https://utpl.edu.ec/recursos/pasos/presencial/inscripcion/portada.jpg",
    },
    {
      title: "utpl",
      url: "https://utpl.edu.ec/recursos/pasos/presencial/inscripcion/paso1.jpg",
    },
    {
      title: "utpl",
      url: "https://utpl.edu.ec/recursos/pasos/presencial/inscripcion/paso2.jpg",
    },
    {
      title: "utpl",
      url: "https://utpl.edu.ec/recursos/pasos/presencial/inscripcion/paso3.png",
    },
  ];

  return (
    <ModalFixed
      color="#699d79"
      id="inscripcionpresencial"
      title="Revisa el proceso de inscripción👌"
      content={
        <> <div className="width400">
          <Gallery gallery={items} width="400"></Gallery></div>
        </>
      }
    ></ModalFixed>
  );
}

export function PresencialNuevos() {
  const items = [
    {
      title: "utpl",
      url: "https://utpl.edu.ec/recursos/pasos/presencial/nuevos/portada.png",
    },
    {
      title: "utpl",
      url: "https://utpl.edu.ec/recursos/pasos/presencial/nuevos/paso1.png",
    },
    {
      title: "utpl",
      url: "https://utpl.edu.ec/recursos/pasos/presencial/nuevos/paso2.png",
    },
    {
      title: "utpl",
      url: "https://utpl.edu.ec/recursos/pasos/presencial/nuevos/paso3.png",
    },
    {
      title: "utpl",
      url: "https://utpl.edu.ec/recursos/pasos/presencial/nuevos/paso4.png",
    },
    {
      title: "utpl",
      url: "https://utpl.edu.ec/recursos/pasos/presencial/nuevos/paso5.png",
    },
    {
      title: "utpl",
      url: "https://utpl.edu.ec/recursos/pasos/presencial/nuevos/paso6.png",
    },
  ];

  return (
    <Modal
      title="Revisa el proceso de matrícula para estudiantes nuevos 📱"
      content={
        <> <div className="width400">
          <Gallery gallery={items} width="400"></Gallery></div>
        </>
      }
    ></Modal>
  );
}

export function PresencialContinuos() {
  const items = [
    {
      title: "utpl",
      url: "https://utpl.edu.ec/recursos/pasos/presencial/continuos/portada.png",
    },
    {
      title: "utpl",
      url: "https://utpl.edu.ec/recursos/pasos/presencial/continuos/paso1.png",
    },
    {
      title: "utpl",
      url: "https://utpl.edu.ec/recursos/pasos/presencial/continuos/paso2.png",
    },
    {
      title: "utpl",
      url: "https://utpl.edu.ec/recursos/pasos/presencial/continuos/paso3.png",
    },
    {
      title: "utpl",
      url: "https://utpl.edu.ec/recursos/pasos/presencial/continuos/paso4.png",
    },
    {
      title: "utpl",
      url: "https://utpl.edu.ec/recursos/pasos/presencial/continuos/paso5.png",
    },
  ];

  return (
    <Modal
      title="Revisa el proceso de matrícula para estudiantes continuos 📱"
      content={
        <> <div className="width400">
          <Gallery gallery={items} width="400"></Gallery></div>
        </>
      }
    ></Modal>
  );
}

export function TecnologiasContinuos() {
  const items = [
    {
      title: "utpl",
      url: "https://utpl.edu.ec/recursos/pasos/tecnologias/continuos/portada.png",
    },
    {
      title: "utpl",
      url: "https://utpl.edu.ec/recursos/pasos/tecnologias/continuos/paso1.png",
    },
    {
      title: "utpl",
      url: "https://utpl.edu.ec/recursos/pasos/tecnologias/continuos/paso2.png",
    },
    {
      title: "utpl",
      url: "https://utpl.edu.ec/recursos/pasos/tecnologias/continuos/paso3.png",
    },
    {
      title: "utpl",
      url: "https://utpl.edu.ec/recursos/pasos/tecnologias/continuos/paso4.png",
    },
    {
      title: "utpl",
      url: "https://utpl.edu.ec/recursos/pasos/tecnologias/continuos/paso5.png",
    },
  ];

  return (
    <Modal
      title="Revisa el proceso de matrícula para estudiantes continuos 📱"
      content={
        <> <div className="width400">
          <Gallery gallery={items} width="400"></Gallery></div>
        </>
      }
    ></Modal>
  );
}

export function PosgradoPostulacion() {
  const items = [
    {
      title: "utpl",
      url: "https://utpl.edu.ec/recursos/pasos/posgrados/postulacion2/portada.png",
    },
    {
      title: "utpl",
      url: "https://utpl.edu.ec/recursos/pasos/posgrados/postulacion2/paso1.png",
    },
    {
      title: "utpl",
      url: "https://utpl.edu.ec/recursos/pasos/posgrados/postulacion2/paso2.png",
    },
    {
      title: "utpl",
      url: "https://utpl.edu.ec/recursos/pasos/posgrados/postulacion2/paso3.png",
    },
    {
      title: "utpl",
      url: "https://utpl.edu.ec/recursos/pasos/posgrados/postulacion2/paso4.png",
    }
  ];

  return (
    <Modal
      title="Revisa el proceso de postulación 👌"
      content={
        <> <div className="width400">
          <Gallery gallery={items} width="400"></Gallery></div>
        </>
      }
    ></Modal>
  );
}

export function PosgradoPostulacionFixed() {
  const items = [
    {
      title: "utpl",
      url: "https://utpl.edu.ec/recursos/pasos/posgrados/postulacion2/portada.png",
    },
    {
      title: "utpl",
      url: "https://utpl.edu.ec/recursos/pasos/posgrados/postulacion2/paso1.png",
    },
    {
      title: "utpl",
      url: "https://utpl.edu.ec/recursos/pasos/posgrados/postulacion2/paso2.png",
    },
    {
      title: "utpl",
      url: "https://utpl.edu.ec/recursos/pasos/posgrados/postulacion2/paso3.png",
    },
    {
      title: "utpl",
      url: "https://utpl.edu.ec/recursos/pasos/posgrados/postulacion2/paso4.png",
    }
  ];

  return (
    <ModalFixed
      color="#07a0d2"
      title="Revisa el proceso de postulación 👌"
      content={
        <>
        <div className="width400">
          <Gallery gallery={items} width="400"></Gallery></div>
        </>
      }
    ></ModalFixed>
  );
}

export function PosgradoNuevos() {
  const items = [
    {
      title: "utpl",
      url: "https://utpl.edu.ec/recursos/pasos/posgrados/matricula/portada.png",
    },
    {
      title: "utpl",
      url: "https://utpl.edu.ec/recursos/pasos/posgrados/matricula/paso1.png",
    },
    {
      title: "utpl",
      url: "https://utpl.edu.ec/recursos/pasos/posgrados/matricula/paso2.png",
    },
    {
      title: "utpl",
      url: "https://utpl.edu.ec/recursos/pasos/posgrados/matricula/paso3.png",
    },
    {
      title: "utpl",
      url: "https://utpl.edu.ec/recursos/pasos/posgrados/matricula/paso4.png",
    },
  ];

  return (
    <Modal
      title="Revisa el proceso de matrícula para estudiantes nuevos 📱"
      content={
        <> <div className="width400">
          <Gallery gallery={items} width="400"></Gallery></div>
        </>
      }
    ></Modal>
  );
}
