import "../css/quote.css";
import { motion } from "framer-motion";
import quote from "../img/quote.png";

function Quote({ content }) {
  return (
    <div className="layout8 padding2">
      <motion.blockquote
        initial={{ scale: 0.8, opacity: 0 }}
        whileInView={{
          scale: 1,
          opacity: 1,
          transition: { delay: 0.3, duration: 0.5 },
        }}
        viewport={{ once: true, amount: 0.2 }}
        className="quote-container"
      >
        <div className="quote-text layout9">
          <img src={quote} alt="Quote icon" /> {content}
        </div>
      </motion.blockquote>
    </div>
  );
}

export default Quote;
