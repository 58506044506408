import BlockLeftImage from "../components/BlockLeftImage";
import BLockRightImage from "../components/BlockRightImage";
import FixedHeader from "../components/FixedHeader";
import Block3Columns from "../components/Block3Columns";
import Modal from "../components/Modal";
import DataFetcherTemplate from "../hooks/fetchTemplate";
import BlockRightImage from "../components/BlockRightImage";
import Block2ColumnsText from "../components/Block2ColumnsText";
import ImageGallery from "../components/ImageGallery";
function FernandoRielo() {
  const DataDisplay = ({ data }) => (
    <>
      <>
        {data.map((item, index) => (
          <>
            {data.map((item, index) => (
              <>
                <FixedHeader video={item.field_video_portada}></FixedHeader>
                <div className="layout8 padding">
                  <center>
                    <div
                      className="width700"
                      dangerouslySetInnerHTML={{
                        __html: item.field_texto_portada,
                      }}
                    />
                  </center>
                </div>
                <BlockLeftImage
                  src={item.field_imagen1}
                  content={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.field_bloque1,
                      }}
                    />
                  }
                ></BlockLeftImage>
                <div className="padding">
                  <div
                    className="layout8"
                    dangerouslySetInnerHTML={{
                      __html: item.field_bloque2,
                    }}
                  />
                  <Block3Columns
                    col1={
                      <Modal
                        title={
                          <>
                            <img src={item.field_imagen3} alt="utpl"></img>
                          </>
                        }
                        content={
                          <div className="graybg padding2">
                            <div
                              className="layout9"
                              dangerouslySetInnerHTML={{
                                __html: item.field_bloque3,
                              }}
                            />
                          </div>
                        }
                      ></Modal>
                    }
                    col2={
                      <Modal
                        title={
                          <>
                            <img src={item.field_imagen4} alt="utpl"></img>
                          </>
                        }
                        content={
                          <div className="graybg padding2">
                            <div
                              className="layout9"
                              dangerouslySetInnerHTML={{
                                __html: item.field_bloque4,
                              }}
                            />
                          </div>
                        }
                      ></Modal>
                    }
                    col3={
                      <Modal
                        title={
                          <>
                            <img src={item.field_imagen5} alt="utpl"></img>
                          </>
                        }
                        content={
                          <div className="graybg padding2">
                            <div
                              className="layout9"
                              dangerouslySetInnerHTML={{
                                __html: item.field_bloque5,
                              }}
                            />
                          </div>
                        }
                      ></Modal>
                    }
                  ></Block3Columns>{" "}
                </div>
                <div className="layout6 padding2">
                  <center>
                    <h2>
                      Frases <b> célebres</b>
                    </h2>
                  </center>
                  <ImageGallery
                    id="gallery1"
                    gallery={item.field_gallery1}
                  ></ImageGallery>{" "}
                </div>
                <BlockRightImage
                  src={item.field_imagen6}
                  content={
                    <>
                      {" "}
                      <div
                        className="layout9"
                        dangerouslySetInnerHTML={{
                          __html: item.field_bloque6,
                        }}
                      />
                      <Modal
                        title={
                          <>
                            <a class="link-with-arrow">Más información</a>
                          </>
                        }
                        content={
                          <div className="graybg padding">
                            {" "}
                            <div
                              className="layout9 "
                              dangerouslySetInnerHTML={{
                                __html: item.field_bloque7,
                              }}
                            />
                          </div>
                        }
                      ></Modal>
                    </>
                  }
                ></BlockRightImage>

                <div className="padding graybg">
                  <Block2ColumnsText
                    col1={
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.field_bloque8,
                        }}
                      />
                    }
                    col2={
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.field_bloque9,
                        }}
                      />
                    }
                  ></Block2ColumnsText>
                </div>
                <div className="layout6 padding2">
                  <center>
                    <h2>
                      Frases <b> célebres</b>
                    </h2>
                  </center>
                  <ImageGallery
                    id="gallery2"
                    gallery={item.field_gallery2}
                  ></ImageGallery>{" "}
                </div>

                <BlockRightImage
                  src={item.field_imagen10}
                  content={
                    <>
                      {" "}
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.field_bloque10,
                        }}
                      />
                      <Modal
                        title={
                          <>
                            <a class="link-with-arrow">Más información</a>
                          </>
                        }
                        content={
                          <div className="graybg padding">
                            {" "}
                            <div
                              className="layout9 "
                              dangerouslySetInnerHTML={{
                                __html: item.field_bloque12,
                              }}
                            />
                          </div>
                        }
                      ></Modal>
                    </>
                  }
                ></BlockRightImage>
                <BlockLeftImage
                  src={item.field_imagen11}
                  content={
                    <>
                      {" "}
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.field_bloque11,
                        }}
                      />
                      <Modal
                        title={
                          <>
                            <a class="link-with-arrow">Más información</a>
                          </>
                        }
                        content={
                          <div className="graybg padding">
                            {" "}
                            <div
                              className="layout9 "
                              dangerouslySetInnerHTML={{
                                __html: item.field_bloque13,
                              }}
                            />
                          </div>
                        }
                      ></Modal>
                    </>
                  }
                ></BlockLeftImage>

                <div className="graybg padding">
                  <center>
                    <div className="width700"> 
                    <h2>
                    <b> ¿Necesitas asistencia </b> para la Semana del
                      Estudiante "Fernando Rielo 2025"?
                    </h2>

                    <p>
                      Te ofrecemos asesoría especializada para resolver
                      consultas relacionadas con la vida y pensamiento de
                      Fernando Rielo, con el fin de apoyarte en tu preparación
                      para los concursos de la Semana del Estudiante.
                    </p>
                    <p>
                      {" "}
                      <b>Horario: </b> martes de 16:00 a 18:00
                    </p>
                    <p>
                      <b>Fecha: </b>del 11 de marzo hasta el 6 de mayo
                    </p>

                    <h2 className="tag3-retoma">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://utpl.zoom.us/j/85826791579 "
                      >
                        <b>utpl.edu.ec/zoom 📹</b>
                      </a>
                    </h2></div>
                  </center>
                </div>
              </>
            ))}
          </>
        ))}
      </>
    </>
  );
  return (
    <>
      {" "}
      <DataFetcherTemplate url="https://utpl.edu.ec/carreras/services/servicio-fernandorielo.json">
        {(data) => <DataDisplay data={data} />}
      </DataFetcherTemplate>
    </>
  );
}

export default FernandoRielo;
