import { NavLink } from "react-router-dom";
import Breadcumbs from "../components/Breadcumbs";
import BlockRightImage from "../components/BlockRightImage";
import BlockLeftImage from "../components/BlockLeftImage";
import Accordion from "../components/Accordion";
import DataFetcherTemplate from "../hooks/fetchTemplate";



import {
  DistanciaContinuos,
  PresencialInscripcion,
  PresencialNuevos,
  PresencialContinuos,
  PosgradoPostulacion,
  PosgradoNuevos,
  DistanciaNuevos,
} from "../components/Pasos";
import BannerGeneral from "../components/BannerGeneral";
import EnLineaPagos from "../components/Enlineapagos";
import PosgradoPagos from "../components/PosgradoPagos";
import PresencialPagos from "../components/PresencialPagos";

function Matriculas() {
  const DataDisplay = ({ data }) => (
    <>
      <div>
        {data.map((item, index) => (
          <div className="wrapper-matricula">
            {data.map((item, index) => (
              <div key={index}>
                <div className="layout6">
                  <center>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.field_texto_portada,
                      }}
                    />{" "}
                  </center>
                </div>

                <div className="padding2">
                  <div className="icon350">
                    <BlockRightImage
                      src="https://utpl.edu.ec/recursos/img/linea.gif"
                      alt="utpl"
                      content={
                        <>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item.field_bloque1,
                            }}
                          />{" "}
                          <div>
                            <Accordion
                              title="Fechas de matrícula"
                              content={
                                <>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: item.field_bloque2,
                                    }}
                                  />
                                </>
                              }
                            ></Accordion>

                            <Accordion
                              title="Calendario académico"
                              content={
                                <>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: item.field_bloque3,
                                    }}
                                  />
                                </>
                              }
                            ></Accordion>
                          </div>
                          <DistanciaNuevos></DistanciaNuevos>
                          <DistanciaContinuos></DistanciaContinuos>
                        </>
                      }
                    ></BlockRightImage>
                  </div>

                  <EnLineaPagos></EnLineaPagos>
                </div>
                <div className="graybg padding2">
                  <div className="icon350">
                    <BlockRightImage
                      src="https://utpl.edu.ec/recursos/img/presencial.gif"
                      alt="utpl"
                      content={
                        <>
                          {" "}
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item.field_bloque4,
                            }}
                          />{" "}
                          <div>
                            <Accordion
                              title="Fechas de matrícula"
                              content={
                                <>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: item.field_bloque5,
                                    }}
                                  />
                                  <Accordion
                                    title="Oferta periodo extraordinario febrero - marzo 2025"
                                    content={
                                      <>
                                        <ul>
                                          <li>
                                            <p>
                                              <a
                                                target="_blank"
                                                href="https://utpl.edu.ec/documentos/oferta/pe12025/salud.pdf"
                                              >
                                                Facultad de Ciencias de la Salud
                                              </a>
                                            </p>{" "}
                                          </li>

                                          <li>
                                            <p>
                                              <a
                                                target="_blank"
                                                href="https://utpl.edu.ec/documentos/oferta/pe12025/naturales.pdf"
                                              >
                                                Facultad de Ciencias Exactas y
                                                Naturales
                                              </a>
                                            </p>{" "}
                                          </li>

                                          <li>
                                            <p>
                                              <a
                                                target="_blank"
                                                href="https://utpl.edu.ec/documentos/oferta/pe12025/ingenieria.pdf"
                                              >
                                                Facultad de Ingenierías y
                                                Arquitectura
                                              </a>
                                            </p>{" "}
                                          </li>
                                        </ul>
                                      </>
                                    }
                                  ></Accordion>
                                </>
                              }
                            ></Accordion>
                            <Accordion
                              title="Calendario académico"
                              content={
                                <>
                                  {" "}
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: item.field_bloque6,
                                    }}
                                  />
                                </>
                              }
                            ></Accordion>
                          </div>
                          <PresencialInscripcion></PresencialInscripcion>
                          <PresencialNuevos></PresencialNuevos>
                          <PresencialContinuos></PresencialContinuos>
                        </>
                      }
                    ></BlockRightImage>

              <PresencialPagos></PresencialPagos>
                  </div>
                </div>
                <div className=" padding2">
                  <div className="icon350">
                    <BlockRightImage
                      src="https://utpl.edu.ec/recursos/img/posgrados.gif"
                      alt="utpl"
                      content={
                        <>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item.field_bloque7,
                            }}
                          />{" "}
                          <div>
                            <Accordion
                              title="Fechas de matrícula"
                              content={
                                <>
                                  {" "}
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: item.field_bloque8,
                                    }}
                                  />
                                </>
                              }
                            ></Accordion>
                            <Accordion
                              title="Calendario académico"
                              content={
                                <>
                                  {" "}
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: item.field_bloque9,
                                    }}
                                  />
                                </>
                              }
                            ></Accordion>
                          </div>
                          <PosgradoPostulacion></PosgradoPostulacion>
                          <PosgradoNuevos></PosgradoNuevos>
                        </>
                      }
                    ></BlockRightImage>
                  </div>
                  <PosgradoPagos></PosgradoPagos>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </>
  );

  return (
    <>
      <div className="margin">
        <Breadcumbs
          content={
            <>
              <NavLink to="/matriculas">Matrículas</NavLink>
            </>
          }
        ></Breadcumbs>
      </div>
      <DataFetcherTemplate url="https://utpl.edu.ec/carreras/services/servicio-matriculas.json">
        {(data) => <DataDisplay data={data} />}
      </DataFetcherTemplate>
      <BannerGeneral></BannerGeneral>
    </>
  );
}

export default Matriculas;
