import DataFetcherTemplate from "../hooks/fetchTemplate";
import BlockLeftImage from "../components/BlockLeftImage";
import BlockRightImage from "../components/BlockRightImage";
import Block2ColumnsText from "../components/Block2ColumnsText";
import Block3ColumnsText from "../components/Block3ColumnsText";

import { NavLink } from "react-router-dom";
import Breadcumbs from "../components/Breadcumbs";
import Accordion from "../components/Accordion";

function Transparencia() {
  const DataDisplay = ({ data }) => (
    <>
      <div>
        {data.map((item, index) => (
          <>
            <div
              className="layout8"
              dangerouslySetInnerHTML={{
                __html: item.field_texto_portada,
              }}
            />
            <div>
              <BlockLeftImage
                src={item.field_imagen1}
                alt="utpl"
                content={
                  <>
                    {" "}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.field_bloque1,
                      }}
                    />
                  </>
                }
              ></BlockLeftImage>
              <div className="graybg">
                <BlockRightImage
                  src={item.field_imagen2}
                  content={
                    <>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.field_bloque2,
                        }}
                      />
                      <Accordion
                        title="Informe de actividades"
                        content={
                          <>
                            {" "}
                            <div
                              dangerouslySetInnerHTML={{
                                __html: item.field_bloque3,
                              }}
                            />
                          </>
                        }
                      ></Accordion>
                    </>
                  }
                ></BlockRightImage>
              </div>
            </div>
            <div className="bluebg padding">
              <Block2ColumnsText
                col1={
                  <>
                    {" "}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.field_bloque4,
                      }}
                    />
                  </>
                }
                col2={
                  <>
                    <Accordion
                      title="Documentos POA"
                      content={
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.field_bloque5,
                          }}
                        />
                      }
                    ></Accordion>
                  </>
                }
              ></Block2ColumnsText>
            </div>
            <div className="graybg padding">
              <BlockRightImage
                src={item.field_imagen6}
                alt="utpl"
                content={
                  <>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.field_bloque6,
                      }}
                    />
                  </>
                }
              ></BlockRightImage>
            </div>
            <div className="padding">
              <Block3ColumnsText
                col1={
                  <>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.field_bloque7,
                      }}
                    />
                  </>
                }
                col2={
                  <>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.field_bloque8,
                      }}
                    />

                    <Accordion
                      title="Documentos"
                      content={
                        <>
                          {" "}
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item.field_bloque9,
                            }}
                          />
                        </>
                      }
                    ></Accordion>
                  </>
                }
                col3={
                  <>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.field_bloque10,
                      }}
                    />

                    <Accordion
                      title="Costos, aranceles, matrículas y derechos"
                      content={
                        <>
                          {" "}
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item.field_bloque11,
                            }}
                          />
                        </>
                      }
                    ></Accordion>
                    <Accordion
                      title="Ejecución presupuestaria
                      "
                      content={
                        <>
                          {" "}
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item.field_bloque12,
                            }}
                          />
                        </>
                      }
                    ></Accordion>
                    <Accordion
                      title="Estados financieros auditados"
                      content={
                        <>
                          {" "}
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item.field_bloque13,
                            }}
                          />
                        </>
                      }
                    ></Accordion>
                    <Accordion
                      title="Remuneraciones"
                      content={
                        <>
                          {" "}
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item.field_bloque14,
                            }}
                          />
                        </>
                      }
                    ></Accordion>
                  </>
                }
              ></Block3ColumnsText>
            </div>
            <div className="graybg">
              <BlockLeftImage
                src={item.field_imagen15}
                content={
                  <>
                    {" "}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.field_bloque15,
                      }}
                    />
                  </>
                }
              ></BlockLeftImage>
            </div>
            <div className=" padding">
              <BlockRightImage
                src={item.field_imagen16}
                alt="utpl"
                content={
                  <>
                    {" "}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.field_bloque16,
                      }}
                    />
                  </>
                }
              ></BlockRightImage>
            </div>
          </>
        ))}
      </div>
    </>
  );
  return (
    <>
      <div className=" margin">
        <Breadcumbs
          content={
            <>
              <NavLink to="/transparencia">Transparencia</NavLink>
            </>
          }
        ></Breadcumbs>
      </div>
      <DataFetcherTemplate url="https://utpl.edu.ec/carreras/services/servicio-transparencia.json">
        {(data) => <DataDisplay data={data} />}
      </DataFetcherTemplate>
    </>
  );
}

export default Transparencia;
