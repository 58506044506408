import BlockLeftImage from "./BlockLeftImage";
import Accordion from "./Accordion";

import "../css/blockpagos.css";

function TecPagos() {
  return (
    <>
      <div className="icon350 descuentos-section" id="descuentos">
        <BlockLeftImage
          src="https://utpl.edu.ec/recursos/img/costos.gif"
          content={
            <>
              <p>
                <b>Periodo abril - junio 2025</b>
              </p>
              <h3>
                Obtén hasta un <b>25% de descuento </b>en tu matrícula{" "}
              </h3>
              <p>
                <p className="yellow">
                  <b>Descuentos:</b>
                </p>
                🟢 <b>12% de descuento </b>por pago al contado, con tarjeta de
                crédito o débito.
              </p>
              <p>
                🟢 <b>10% de descuento adicional </b>si eres UTPL Alumni.
              </p>
              <div className="padding2">
              <Accordion
                title="Periodo abril - agosto 2025"
                content={
                  <>
                    {" "}
                    <h3>
                      Obtén hasta un <b>45% de descuento </b>en tu matrícula{" "}
                    </h3>
                    <p>
                      <p className="yellow">
                        <b>Descuentos:</b>
                      </p>
                      🟢 <b>12% de descuento</b> en tu matrícula hasta el 01 de abril. 🎓✨
                    </p>
                    <p>
                      🟢 <b>10% de descuento adicional </b>si eres UTPL Alumni.
                    </p>{" "}
                  </>
                }
              ></Accordion>{" "}
              </div>
              <p className="yellow">
                <b>Becas:</b>
              </p>
              <p>
                🟢 <b> 10% de descuento adicional</b> por Beca Convenio.
              </p>
              <a
                className="link-with-arrow"
                target="_blank"
                href="https://becas.utpl.edu.ec/convenio"
              >
                Postula hoy mismo
              </a>
              <br></br>
              <a
                className="link-with-arrow"
                target="_blank"
                href="https://becas.utpl.edu.ec"
              >
                Descubre más opciones de becas diseñadas para ti
              </a>
              <h4>
                <b>💳 ¡Difiere tu matrícula </b> <br></br> hasta 24 meses sin
                intereses!
              </h4>
              <a
                className="link-with-arrow"
                target="_blank"
                href="https://utpl.edu.ec/documentos/opcionespago.pdf"
              >
                Conoce aquí nuestras opciones de pago
              </a>
              <br></br> <br></br>
              <Accordion
                title="Plan de pagos"
                content={
                  <>
                    <p>
                      <b>Plan de pagos:</b>
                    </p>

                    <ul>
                      <li>
                        <p>
                          Paga 50% al momento de la matrícula y 50% 30 días
                          después.
                        </p>
                      </li>
                    </ul>
                    <p></p>
                  </>
                }
              ></Accordion>
            </>
          }
        ></BlockLeftImage>
      </div>
    </>
  );
}

export default TecPagos;
