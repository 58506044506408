import DataFetcherTemplate from "../../hooks/fetchTemplate";
import BlockRightImage from "../../components/BlockRightImage";
import Notification from "../../components/Notification";
import BlockLeftImage from "../../components/BlockLeftImage";
import BlockImageCabecera from "../../components/BlockImageCabecera";
import BannerGeneral from "../../components/BannerGeneral";
import Block3Columns from "../../components/Block3Columns";
import ConectUs from "../../components/ConectUs";

const MisionSemanaSanta = () => {
  const DataDisplay = ({ data }) => (
    <>
      {data.map((item, index) => (
        <div key={index}>
          <BlockImageCabecera
            src={item.field_portada}
            title={
              <>
                <div
                  dangerouslySetInnerHTML={{ __html: item.field_texto_portada }}
                />
              </>
            }
          ></BlockImageCabecera>

          <Notification
            content={
              <div dangerouslySetInnerHTML={{ __html: item.field_bloque1 }} />
            }
          ></Notification>
          <div className="padding">
            <BlockRightImage
              src={item.field_imagen2}
              content={
                <div dangerouslySetInnerHTML={{ __html: item.field_bloque2 }} />
              }
            ></BlockRightImage>

            <BlockLeftImage
              src={item.field_imagen3}
              content={
                <div dangerouslySetInnerHTML={{ __html: item.field_bloque3 }} />
              }
            ></BlockLeftImage>
          </div>

          <div className="graybg padding">
            <Block3Columns
              col1={
                <div dangerouslySetInnerHTML={{ __html: item.field_bloque4 }} />
              }
              col2={
                <div dangerouslySetInnerHTML={{ __html: item.field_bloque5 }} />
              }
              col3={
                <div dangerouslySetInnerHTML={{ __html: item.field_bloque6 }} />
              }
            ></Block3Columns>
          </div>

          <div className="padding2 bluebg">
            <Notification
              content={
                <div dangerouslySetInnerHTML={{ __html: item.field_bloque7 }} />
              }
            ></Notification>
          </div>

          <div
            className="margin"
            dangerouslySetInnerHTML={{ __html: item.field_bloque8 }}
          />
          <ConectUs
            facebook="https://www.facebook.com/utplmisiones"
            x="https://twitter.com/utplmisiones"
            instagram="https://www.instagram.com/utplmisiones/"
            tiktok="https://www.tiktok.com/@utplmisiones?"
            youtube="https://www.youtube.com/channel/UCdqj_afvXeZHgjc652VJ0Wg/featured"
          >
            {" "}
          </ConectUs>
        </div>
      ))}
    </>
  );

  return (
    <>
      <DataFetcherTemplate url="https://utpl.edu.ec/carreras/services/servicio-misionsemanasanta.json">
        {(data) => <DataDisplay data={data} />}
      </DataFetcherTemplate>
      <BannerGeneral></BannerGeneral>
    </>
  );
};

export default MisionSemanaSanta;
