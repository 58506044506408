import { motion } from "framer-motion";
import Block3Columns from "../../components/Block3Columns";
import FixedHeader from "../../components/FixedHeader";
import DataFetcherTemplate from "../../hooks/fetchTemplate";
import ModalFixed from "../../components/ModalFixed";
import BannerGeneral from "../../components/BannerGeneral";
import ImageGallery from "../../components/ImageGallery";

function TituloValidable() {
  const DataDisplay = ({ data }) => (
    <>
      {data.map((item, index) => (
        <>
          <FixedHeader
            src={item.field_portada}
            span2={
              <>
                Título <b>UTPL</b>
              </>
            }
            span3="validable en"
            span4="Estados Unidos"
          ></FixedHeader>
          <div className=" padding bluebg">
            <div className="layout8">
              <center>
                <motion.div
                  initial={{ y: 150, opacity: 0 }}
                  whileInView={{
                    y: 0,
                    opacity: 1,
                    transition: {
                      type: "spring",
                      stiffness: 200,
                    },
                  }}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.field_bloque1,
                    }}
                  />{" "}
                </motion.div>

                <motion.div
                  initial={{ y: -150, opacity: 0 }}
                  whileInView={{
                    y: 0,
                    opacity: 1,
                    transition: {
                      type: "spring",
                      stiffness: 200,
                      delay: 0.1,
                    },
                  }}
                >
                  <div className="padding2 flex-center iconos-validable">
                    <div>
                      <span class="material-icons">schedule</span>{" "}
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.field_bloque2,
                        }}
                      />{" "}
                    </div>

                    <div>
                      <span class="material-icons">payments</span>{" "}
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.field_bloque3,
                        }}
                      />{" "}
                    </div>
                  </div>
                  <h3>
                    <a href="#validacion"> ¡Solicita ya la validación! </a>
                  </h3>
                </motion.div>
              </center>
            </div>
          </div>

          <div className="padding ">
            <div
              dangerouslySetInnerHTML={{
                __html: item.field_bloque4,
              }}
            />{" "}
            <Block3Columns
              col1={
                <>
                  <div className="icon100">
                    <img src={item.field_imagen5} alt="utpl"></img>
                  </div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.field_bloque5,
                    }}
                  />{" "}
                </>
              }
              col2={
                <>
                  <div className="icon100">
                    <img src={item.field_imagen6} alt="utpl"></img>
                  </div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.field_bloque6,
                    }}
                  />{" "}
                </>
              }
              col3={
                <>
                  <div className="icon100">
                    <img src={item.field_imagen7} alt="utpl"></img>
                  </div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.field_bloque7,
                    }}
                  />{" "}
                </>
              }
            ></Block3Columns>
          </div>

          <div className="graybg padding">
            <div className="layout8">
              <div
                dangerouslySetInnerHTML={{
                  __html: item.field_bloque8,
                }}
              />
              <div
                dangerouslySetInnerHTML={{
                  __html: item.field_bloque9,
                }}
              />
            </div>
            <div className="relative">
              <div
                className="layout8"
                dangerouslySetInnerHTML={{
                  __html: item.field_bloque10,
                }}
              />
              <ModalFixed
                title={<>Proceso certificado de notas 🖊</>}
                content={
                  <>
                    <ImageGallery gallery={item.field_gallery1}></ImageGallery>
                  </>
                }
              ></ModalFixed>
            </div>

            <div className="relative">
              <div
                className="layout8"
                dangerouslySetInnerHTML={{
                  __html: item.field_bloque11,
                }}
              />
              <ModalFixed
                title={<>Proceso para solicitar título traducido 🖊</>}
                content={
                  <>
                    <ImageGallery gallery={item.field_gallery2}></ImageGallery>
                  </>
                }
              ></ModalFixed>
            </div>

            <div
              className="layout8 padding2"
              dangerouslySetInnerHTML={{
                __html: item.field_bloque12,
              }}
            />

            <div
              className="layout8 "
              dangerouslySetInnerHTML={{
                __html: item.field_bloque13,
              }}
            />
          </div>
        </>
      ))}
    </>
  );

  return (
    <>
      <DataFetcherTemplate url="https://utpl.edu.ec/carreras/services/servicio-titulo.json">
        {(data) => <DataDisplay data={data} />}
      </DataFetcherTemplate>

      <div className="padding">
        <center>
          <h2>¿Necesitas ayuda?</h2>
          <h2 className="tag3-retoma">
            {" "}
            Un asesor está listo para ayudarte. Conéctate con nosotros en{" "}
            <a target="_blank" rel="noreferrer" href="https://utpl.edu.ec/zoom">
              <b>utpl.edu.ec/zoom 📹</b>
            </a>
          </h2>
        </center>
      </div>
      <BannerGeneral></BannerGeneral>
    </>
  );
}

export default TituloValidable;
