import "../css/blockcolumn.css";
import { motion } from "framer-motion";
function Block2ColumnsText(props) {
  return (
    <div className="block-columntext block-column ">
      <div className="layout8">
        <div className="col2">
          <motion.div
            initial={{ x: -100, opacity: 0 }}
            whileInView={{
              x: 0,
              opacity: 1,
              transition: {
                delay: 0.1,
              },
            }}
          >
            <div className=" content">{props.col1}</div>
          </motion.div>
          <motion.div
            initial={{ x: -100, opacity: 0 }}
            whileInView={{
              x: 0,
              opacity: 1,
              transition: {
                delay: 0.3,
              },
            }}
          >
            <div className=" content">{props.col2}</div>
          </motion.div>
        </div>
      </div>
    </div>
  );
}

export default Block2ColumnsText;
